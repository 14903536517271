import React from 'react'
import { AppBar as AppBarUI, IconButton } from 'material-ui'
import { NavLink } from 'react-router-dom'

import TopBarIconNav from '../TopBarIconNav/TopBarIconNav'

const APP_BAR_TITLE = 'Xenocide'


class AppBar extends React.Component {
  route = ({ to, tooltip, iconClassName }) => (
    <span className={'icon_button'}>
      <NavLink
        to={to}
        // activeClassName={'active'}
      >
        <IconButton tooltip={tooltip} iconClassName={iconClassName} />
      </NavLink>
    </span>
  )

  render() {
    // const { children } = this.props
    const isAuthenticated = false

    return (
      <AppBarUI
        title={APP_BAR_TITLE.toUpperCase()}
        iconStyleRight={{ margin: 0, display: 'flex', alignItems: 'center' }}
        showMenuIconButton={true}
        style={{ zIndex: 'inherit' }}
        iconElementLeft={(
          <div style={{ position: 'relative', itemAlign: 'center', display: 'flex',  }}>
            {isAuthenticated && this.route({ to: '/dashboard', tooltip: 'dashboard', iconClassName: 'fa fa-dashboard' })}
            {isAuthenticated && this.route({ to: '/theorys', tooltip: 'teorie', iconClassName: 'fa fa-knowledge' })}
            {isAuthenticated && this.route({ to: '/scene', tooltip: 'symulacja', iconClassName: 'fa fa-xenocide-icon-collection' })}
            {/* {isAuthenticated && <NavLink to={'/dashboard'} className={'icon_button'} activeClassName={'active'}><IconButton tooltip="dashboard" iconClassName={'fa fa-dashboard'} /></NavLink>} */}
            {/* {isAuthenticated && <NavLink to={'/theorys'} className={'icon_button'} activeClassName={'active'}><IconButton tooltip="teorie" iconClassName={'fa fa-graduation-cap'} /></NavLink>} */}
            {/* {isAuthenticated && <NavLink to={'/scene'} className={'icon_button'} activeClassName={'active'}><IconButton tooltip="symulacja" iconClassName={'fa fa-globe'} /></NavLink>} */}
          </div>
        )}
        iconElementRight={(
          <div style={{ position: 'relative', itemAlign: 'center', display: 'flex', flex: 1, height: '100%' }}>
            {/* {!isAuthenticated && <Link to={'/login'}><FlatButton label="Login" icon={<FontIcon className={'fa fa-sign-in'}/>} /></Link>} */}
            {/* {isAuthenticated && <AvatarMenu user={user} />} */}
            {/* {!isAuthenticated && <Link to={'/register'}><RaisedButton primary label="Register" icon={<FontIcon className={'fa fa-user-plus'} />} /></Link>} */}
            <TopBarIconNav />
          </div>
        )}
      />
    )
  }
}

export default AppBar
