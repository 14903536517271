// import { FetchPromise } from 'duchunet-utils'
import FetchPromise from '../FetchAction'
import Actions from '../Actions'


export const SCENE = {
  SET_OPTION: 'scene/SET_OPTION',
  MOVE_CAMERA_TO_TARGET: 'scene/MOVE_CAMERA_TO_TARGET',
  SWITCH_DIMENSION: 'scene/SWITCH_DIMENSION',

  SCENE_CHANGE_DISPLAY_MODE: 'SCENE_CHANGE_DISPLAY_MODE',
  SCENE_CHANGE_SYSTEM: 'SCENE_CHANGE_SYSTEM',
  SCENE_OBJECT_SELECTED: 'SCENE_OBJECT_SELECTED',
}


class SceneActions extends Actions {
  exposed() {
    return {
      toggleSceneOption: this.toggleSceneOption,
      moveCameraToTarget: this.moveCameraToTarget,
      switchDimension: this.switchDimension,
      // changeSystem: this.changeSystem,
      // changeDisplayMode: this.changeDisplayMode,
      // selectObject: this.selectObject,
    }
  }

  toggleSceneOption = (option_key, forced_value) => (dispatch, getState) => {
    return dispatch({
      type: SCENE.SET_OPTION,
      payload: { [option_key]: forced_value },
    })
  }

  moveCameraToTarget = (targetName) => (dispatch, getState) => {
    return dispatch({
      type: SCENE.MOVE_CAMERA_TO_TARGET,
      payload: targetName,
    })
  }

  switchDimension = (dimension) => (dispatch, getState) => {
    return dispatch({
      type: SCENE.SWITCH_DIMENSION,
      payload: dimension,
    })
  }

  // selectObject = (objectData) => (dispatch, getState) => {
  //   return dispatch({
  //     type: SCENE.SCENE_OBJECT_SELECTED,
  //     payload: objectData,
  //   })
  // }


  // changeDisplayMode = (display_mode) => (dispatch, getState) => {
  //   return dispatch({
  //     type: SCENE.SCENE_CHANGE_DISPLAY_MODE,
  //     payload: { display_mode },
  //   })
  // }
  //
  // changeSystem = (systemId) => (dispatch, getState) => {
  //   return dispatch({
  //     type: SCENE.SCENE_CHANGE_SYSTEM,
  //     payload: { systemId },
  //   })
  // }
}

const SceneActionsInstance = new SceneActions()
export default SceneActionsInstance.exposed()
