import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from 'material-ui'
import Icon from 'components/Icons/Icon'
import { FormattedMessage, injectIntl } from 'react-intl'

class LoginButton extends React.PureComponent {
  static defaultProps = {
    tooltipPosition: null,
  }
  static propTypes = {
    tooltipPosition: PropTypes.string,
  }

  render() {
    // const { children, ...props } = this.props
    return (
      <IconButton 
        tooltip={<FormattedMessage id='main.login' defaultMessage='login'/>} 
        tooltipPosition={this.props.tooltipPosition}
        // {...props}
      >
        <Icon iconName={'sign_in'} />
      </IconButton>
    )
  }
}

export default injectIntl(LoginButton)
