import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Toolbar, ToolbarGroup, IconMenu, IconButton, MenuItem, Divider } from 'material-ui'
import SettingsIcon from 'material-ui/svg-icons/action/settings'
import SupervisorAccountIcon from 'material-ui/svg-icons/action/supervisor-account'
import AccountBoxIcon from 'material-ui/svg-icons/action/account-box'
import VideoLabelIcon from 'material-ui/svg-icons/av/video-label'
import PowerSettingsIcon from 'material-ui/svg-icons/action/power-settings-new'
import BugReportIcon from 'material-ui/svg-icons/action/bug-report'
import PetsIcon from 'material-ui/svg-icons/action/pets'
import SecurityIcon from 'material-ui/svg-icons/hardware/security'

import SwitchLocale from 'components/Locale/SwitchLocale'
import AppSyncStatus from 'modules/app/components/AppSyncStatus'
import DimensionIconButton from 'modules/scene/components/DimensionIconButton'
import FullscreenIconButton from 'components/Utils/FullscreenIconButton'
import Icon from 'components/Icons/Icon'
import AuthenticatedWrapper from '../AuthenticatedWrapper'
import './AppFooter.scss'

const VERSION = __CONFIG.VERSION


class AppFooter extends React.Component {
  static contextTypes = {
    manager: PropTypes.object,
  }

  openWindow = (windowId) => (event) => {
    event.stopPropagation()
    this.context.manager.openByType(windowId)
  }

  render() {
    // const { children } = this.props
    return (
      <Toolbar className={'app_footer_component'}>
        <ToolbarGroup firstChild>
          <AppSyncStatus />
          <div className={'version_info'}>
            <span>version: {VERSION}</span>
          </div>
        </ToolbarGroup>
        {/* <ToolbarGroup>
          <div>
            <SocketStatus />
          </div>
        </ToolbarGroup> */}
        <ToolbarGroup lastChild>
          <div>
            {/* <span><NavWindow name={'Credits'}>Credits</NavWindow> | </span> */}
            {/* <span><NavWindow name={'SceneSettings'}>SceneSettings</NavWindow> | </span> */}
            {/* <span><Link to={'/admin'}>admin</Link> | </span> */}
            <SwitchLocale tooltipPosition={'top-left'} />
            <FullscreenIconButton tooltipPosition={'top-left'} />
          </div>
          <AuthenticatedWrapper>
            <div style={{ display: 'contents' }}>
              <DimensionIconButton tooltipPosition={'top-left'} />
            </div>
          </AuthenticatedWrapper>
          <IconMenu
            iconButtonElement={<IconButton tooltip={'app menu'} tooltipPosition={'top-left'}><SettingsIcon /></IconButton>}
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            targetOrigin={{horizontal: 'left', vertical: 'bottom'}}
          >
            {/*<Divider />*/}
            <AuthenticatedWrapper>
              <div style={{ display: 'contents' }}>
                <MenuItem
                  value="scene" 
                  leftIcon={<VideoLabelIcon />}
                  className={'capitalize'}
                  primaryText={<FormattedMessage id='interface.scene_settings' defaultMessage='scene settings' />} 
                  onClick={this.openWindow('scene-settings-window')}
                />
                <MenuItem
                  value="systems" 
                  leftIcon={<Icon iconName={'system'} />}
                  className={'capitalize'}
                  primaryText={<FormattedMessage id='interface.systems_list' defaultMessage='systems list' />} 
                  onClick={this.openWindow('systems-list-window')}
                />
                <MenuItem
                  value="account" 
                  leftIcon={<AccountBoxIcon />}
                  className={'capitalize'}
                  primaryText={<FormattedMessage id='interface.account_settings' defaultMessage='account settings' />} 
                  onClick={this.openWindow('account-window')}
                />

                <Divider />
              </div>
            </AuthenticatedWrapper>
            <MenuItem
              value="bug" 
              leftIcon={<BugReportIcon />}
              className={'capitalize'}
              primaryText={<FormattedMessage id='main.bug_report' defaultMessage='bug report'/>} 
              onClick={this.openWindow('bug-window')}
            />
            <MenuItem
              value="credits" 
              leftIcon={<PetsIcon />}
              className={'capitalize'}
              primaryText={<FormattedMessage id='main.credits' defaultMessage='credits'/>} 
              onClick={this.openWindow('credits-window')}
            />
            <MenuItem
              value="terms" 
              leftIcon={<SecurityIcon />}
              className={'capitalize'}
              primaryText={<FormattedMessage id='main.terms' defaultMessage='terms'/>} 
              onClick={this.openWindow('terms-window')}
            />
            <AuthenticatedWrapper>
              <div style={{ display: 'contents' }}>
                <MenuItem 
                  value="exit" 
                  leftIcon={<PowerSettingsIcon />}
                  className={'capitalize'} 
                  primaryText={<FormattedMessage id='interface.exit' defaultMessage='exit'/>} 
                  disabled 
                />

                {__CONFIG.ADMIN_INTERFACE && (
                  <div>
                    <Divider />
                    <Link to={'/admin'}>
                      <MenuItem 
                        value="admin" 
                        leftIcon={<SupervisorAccountIcon />}
                        className={'capitalize'} 
                        primaryText={<FormattedMessage id='main.admin' defaultMessage='admin'/>} 
                      />
                    </Link>
                  </div>
                )}
              </div>
            </AuthenticatedWrapper>
          </IconMenu>
        </ToolbarGroup>
      </Toolbar>
    )
  }
}

export default AppFooter
