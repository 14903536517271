import update from 'react-addons-update'
import moment from 'moment'
import Reducer from '../Reducer'
import { C } from './AppActions'
import quotes from '../../components/Logo/quotes'

class AppReducer extends Reducer {
  initialState = {
    quote_id: Math.floor((Math.random() * quotes.length) + 0),
    quote_at: null,
    locale: null,
  }

  hookedActions = {
    ['persist/REHYDRATE']: this.onRehydrate,
    [C.SWITCH_LOCALE]: this.onSwitchLocale,
  }

  onSwitchLocale(state, action) {
    return update(state, { locale: { $set: action.payload } })
  }

  onRehydrate(state, action) {
    if (!action.payload || !action.payload.App) return state
    const { App } = action.payload
    const query = {}
    if (moment().format('YYYYMMDD') != App.quote_at) Object.assign(query, { 
      quote_id: { $set: state.quote_id }, 
      quote_at: { $set: moment().format('YYYYMMDD') }, 
    })

    return update(App, query)
  }
}

const AppReducerInstance = new AppReducer()
export default AppReducerInstance.exportReducer()
