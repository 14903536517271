class Reducer {
  hookedActions = {}
  initialState = {}

  reducer(state = { ...this.initialState }, action) {
    if (this.hookedActions[action.type]) {
      return this.hookedActions[action.type].bind(this)(state, action)
    }
    return state
  }

  exportReducer() {
    return (...props) => this.reducer(...props)
  }

  getResponse(res) {
    return res.payload;
  }

  getError(res) {
    try {
      console.error('getError', res.payload.response);
      if (res.payload.response) {
        // const data = JSON.parse(res.payload.response.data);
        const data = res.payload.response.data || {};
        return {
          status: res.payload.status,
          data: data,
          errors: data.errors
        }
      } else {
        return {
          status: res.payload.message,
          data: {},
          errors: [ res.payload.message ]
        }
      }
    } catch (e) {
      // console.error(e);
    }
    return {};
  }
}

export default Reducer
