import FetchPromise from '../FetchAction'
import Actions from '../Actions'
import FetchAction from '../FetchAction'


export const THEORYS = {
  // SET_OPTION: 'scene/SET_OPTION',

  API_GET_THEORYS: 'api/GET_THEORYS',
  API_GET_THEORYS_SUCCESS: 'api/GET_THEORYS_SUCCESS',
  API_GET_THEORYS_FAILURE: 'api/GET_THEORYS_FAILURE',
}


class TheoryActions extends Actions {
  // API_SERVER_URL = process.env.NODE_ENV === 'development'
  //   ? 'http://localhost:3030/api/theorys'
  //   : 'http://xenocide-duchunet.rhcloud.com/api/theorys';
  API_SERVER_URL = __CONFIG.API_HOST + '/theory';

  exposed() {
    return {
      // toggleTheoryOption: this.toggleTheoryOption,
      getTheorys: this.getTheorys.bind(this),
    }
  }

  getTheorys(model) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: '/' }),
        data: model,
        type: THEORYS.API_GET_THEORYS,
        method: 'GET',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  // toggleTheoryOption = (option_key, forced_value) => (dispatch, getState) => {
  //   return dispatch({
  //     type: THEORYS.SET_OPTION,
  //     payload: { [option_key]: forced_value },
  //   })
  // }
}

const TheoryActionsInstance = new TheoryActions()
export default TheoryActionsInstance.exposed()
