import React from 'react'
import moment from 'moment'


class FromNow extends React.Component {
  static defaultProps = {
    from: null,
  }
  state = {
    last_update: null,
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ last_update: new Date() })
    }, 30000);
  }
  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  render() {
    if (!this.props.from) return null

    return (
      <span>{moment(this.props.from).fromNow()}</span>
    )
  }
}

export default FromNow
