import io from 'socket.io-client'


class SocketClient {
  socket = null
  props = {}

  constructor(props = {}) {
    this.props = {
      url: props.url || 'ws://localhost:3030',
    }
  }

  getConnectionOptions() {
    return {
      forceNew: true,
      reconnectionAttempts: 5,
    }
  }
  getConnectionUrl(url = '') {
    if (url && url.startsWith('/')) return `${this.props.url}${url}`
    return this.props.url
  }

  connect(props = {}) {
    const { url, uid, token, on = {} } = props

    const connectOpt = this.getConnectionOptions()
    if (uid && token) connectOpt.query = `token=${token}&uid=${uid}`

    const socket = io(this.getConnectionUrl(url), connectOpt)

    Object.entries(on).forEach(([key, fun]) => socket.on(key, fun))

    // socket.on('connect', data => {
    //   console.log('>> socket connected >>');
    //
    //   // this.store.dispatch(WebsocketActions.socketConnected());
    //   // socket.emit('msg', { msg: 'data from client' });
    //   // this.store.dispatch(WebsocketActions.getServerTime());
    //   // this.store.dispatch(WebsocketActions.getUniversumTime());
    // })
    return socket
  }

  disconnect() {

  }
}

export default SocketClient
