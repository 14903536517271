import React from 'react'
import PropTypes from 'prop-types'
// import { Grid, Row, Col } from 'react-flexbox-grid'
import { FormattedMessage, injectIntl } from 'react-intl'

// import Lightbox from 'react-images'
import { MenuItem, IconMenu, IconButton } from 'material-ui'
// import SettingsIcon from 'material-ui/svg-icons/action/settings'
// import Icon from 'components/Icons/Icon'
import './SwitchLocal.scss'
// import Page from 'components/Page/Page'
// import Card from 'components/Card/Card'
// import Logo from 'components/Logo/Logo'
// import Credits from 'components/Credits/Credits'
// import Gallery from 'components/Gallery/Gallery'
// import ArticlesList from 'modules/articles/components/ArticlesList'
import flagPl from './flags/locale-pl.png'
import flagEn from './flags/locale-en.png'


class SwitchLocale extends React.Component {
  static contextTypes = {
    setLocale: PropTypes.func,
  }
  static propTypes = {
    intl: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  

  render() {
    const items = [
      {
        locale: 'en',
        name: 'english',
        flag: flagEn,
      },
      {
        locale: 'pl',
        name: 'polish',
        flag: flagPl,
      }
    ].map(item => (
      <MenuItem 
        key={item.locale} 
        value={item.locale} 
        primaryText={item.name} 
        onClick={() => this.context.setLocale(item.locale)}
        insetChildren={true}
        checked={this.props.intl.locale == item.locale}
        // rightIcon={<Icon iconName={`locale-${item.locale}`} />}
        rightIcon={<img src={item.flag} />}
      />
    ))

    // return (
    //   <SelectField
    //     value={this.state.value}
    //     onChange={this.handleChange}
    //     maxHeight={200}
    //   >
    //     {items}
    //   </SelectField>
    // )
    return (
      <IconMenu
        // iconButtonElement={<IconButton tooltip={'language'} tooltipPosition={'top-left'}><SettingsIcon /></IconButton>}
        iconButtonElement={(
          <IconButton 
            tooltip={<FormattedMessage id='main.language' defaultMessage='language'/>} 
            className={'current_flag'}
            tooltipPosition={'top-left'}
          >
            <img src={this.props.intl.locale == 'pl' ? flagPl : flagEn} />
          </IconButton>
        )}
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        targetOrigin={{horizontal: 'left', vertical: 'bottom'}}
      >
        {items}
      </IconMenu>
    )
  }
}

export default injectIntl(SwitchLocale)
