// import { FetchPromise } from 'duchunet-utils'
import Actions from '../Actions'
import FetchAction from '../FetchAction'


const C = {
  API_LOGIN: 'api/LOGIN',
  API_LOGIN_SUCCESS: 'api/LOGIN_SUCCESS',
  API_LOGIN_FAILURE: 'api/LOGIN_FAILURE',
  API_REGISTER: 'api/REGISTER',
  API_REGISTER_SUCCESS: 'api/REGISTER_SUCCESS',
  API_REGISTER_FAILURE: 'api/REGISTER_FAILURE',
  API_LOGOUT: 'api/LOGOUT',
  API_LOGOUT_SUCCESS: 'api/LOGOUT_SUCCESS',
  API_LOGOUT_FAILURE: 'api/LOGOUT_FAILURE',
  API_TOKEN_EXPIRED: 'api/TOKEN_EXPIRED',
}
export { C }

class AuthActions extends Actions {
  actionMap = {
    authLogin: this.authLogin.bind(this),
    registerUser: this.registerUser.bind(this),
  }

  authLogin(model, abortController) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: 'login' }),
        data: model,
        type: C.API_LOGIN,
        method: 'POST',
        abortController: abortController,
      }

      return api.fetchUrl(dispatch, getState, fetchOptions)
    }
  }

  registerUser(model, signal) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: 'register' }),
        data: model,
        type: C.API_REGISTER,
        method: 'POST',
        signal: signal,
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }
}

const AuthActionsInstance = new AuthActions()
export default AuthActionsInstance.export()
