import { registerReducer } from './store/store'

const modules = {}

export default (store) => (name, moduleProvider) => {
  if(modules.hasOwnProperty(name)) {
    return Promise.resolve(modules[name])
  }
  else {
    return moduleProvider.then(mod => {
      if (mod.reducer != null) registerReducer(store, name, mod.reducer)
      return mod
    })
  }
}
