// import { FetchPromise } from 'duchunet-utils'
import Actions from '../Actions'
import FetchAction from '../FetchAction'


const ORDERS = {
  API_GET_ORDERS: 'api/GET_ORDERS',
  API_GET_ORDERS_SUCCESS: 'api/GET_ORDERS_SUCCESS',
  API_GET_ORDERS_FAILURE: 'api/GET_ORDERS_FAILURE',
}
export { ORDERS }

class OrdersActions extends Actions {
  // API_SERVER_URL = process.env.NODE_ENV === 'development'
  //   ? 'http://localhost:3030/api/orders'
  //   : 'http://xenocide-duchunet.rhcloud.com/api/orders';
  API_SERVER_URL = __CONFIG.API_HOST + '/api/orders';
  actionMap = {
    getOrders: this.getOrders.bind(this),
  }

  getOrders(model) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: '/' }),
        data: model,
        type: ORDERS.API_GET_ORDERS,
        method: 'GET',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }
}

const OrdersActionsInstance = new OrdersActions()
export default OrdersActionsInstance.export()
