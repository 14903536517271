import SocketClient from './SocketClient'
import { C } from './SocketActions'

let client = null
let socket_main = null
let socket_user = null
let socket_admin = null

const SocketMiddleware = store => next => action => {
  if (!store.getState().Socket) return next(action)
  if (!client) {
    client = new SocketClient({ url: __CONFIG.WS_HOST })
    socket_main = client.connect({
      on: {
        connect: data => store.dispatch({ type: 'socket/CONNECTED' }),
        disconnect: data => store.dispatch({ type: 'socket/DISCONNECTED' }),
        update: data => store.dispatch({ type: data.type, payload: data.payload }),
        reconnect_failed: data => store.dispatch({ type: 'socket/RECONNECT_FAILED' }),
      }
    })
  }
  
  switch (action.type) {
    case 'api/LOGIN_SUCCESS':
      socket_user = client.connect({
        url: '/user',
        uid: action.payload.uid,
        token: action.payload.token,
        // user_id: action.payload.data.id,
        on: {
          connect: data => store.dispatch({ type: 'socket/CONNECTED_TO_USER' }),
          disconnect: data => store.dispatch({ type: 'socket/DISCONNECTED_FROM_USER' }),
          // notification: data => console.warn('notification', data)
          notification: data => store.dispatch({ type: data.type, payload: data.payload }),
          update: data => store.dispatch({ type: data.type, payload: data.payload }),
          reconnect_failed: data => store.dispatch({ type: 'socket/RECONNECT_FAILED_TO_USER' }),
        }
      })
      return next(action)

    case 'api/TOKEN_EXPIRED':
    case 'api/LOGOUT_SUCCESS':
      // client.disconnect()
      socket_user.close()
      socket_user = null
      return next(action)

      // case C.WEBSOCKET_CONNECT:
      //   // debug('>>> ' + C.WEBSOCKET_CONNECT, action)
      //   client.connect(action.payload)
      //   return

      // case C.WEBSOCKET_SUBSCRIBE:
      //   // debug('>>> ' + C.WEBSOCKET_SUBSCRIBE, action)
      //   client.subscribeTo(action.channel, action.payload)
      //   return

      // case C.WEBSOCKET_DISCONNECT:
      //   // debug('>>> ' + C.WEBSOCKET_DISCONNECT, action)
      //   client.disconnect()
      //   // store.dispatch({type: C.WEBSOCKET_DISCONNECTED})
      //   return

      // case C.WEBSOCKET_SEND:
      //   // debug('>>> ' + C.WEBSOCKET_SEND, action)
      //   client.emit(action.channel, action.payload)
      //   return

    default:
      return next(action)
  }
}

const RemoteActionMiddleware = store => next => action => {
  if (action.meta && action.meta.remote && action.meta.from !== 'server') {
    if (!client)
      return console.warn('No socket connection for action: ', action.type)
    client.emit('action', action)
  }
  return next(action)
}

export {
  SocketMiddleware,
  RemoteActionMiddleware
}
