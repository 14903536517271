'use strict'

const RESIZE = 'RESIZE'
const INACTIVE = 'INACTIVE'
const MOVE = 'MOVE'

class Window {
  static defaults = {
    id: undefined,
    x: 0,
    y: 50,
    index: 0,
    width: 250,
    height: 300,
    maxWidth: Infinity,
    minWidth: 100,
    maxHeight: Infinity,
    minHeight: 100,
    title: '',
    isOpen: true,
    component: undefined
  }
  constructor(props = {}) {
    Object.assign(this, { ...Window.defaults }, props)
    this._event = new EventTarget()
    this.updateBounding()
  }
  setComponent(component) {
    this.component = component
    this.emit('change:component')
    this.emit('change')
  }
  open() {
    if (this.isOpen) { return }
    this.isOpen = true
    console.log(this, window.innerWidth, window.outerWidth)
    
    this.emit('change:open')
    this.emit('change')
  }
  close() {
    if (!this.isOpen) { return }
    this.isOpen = false
    this.emit('change:open')
    this.emit('change')
  }
  setIndex(index) {
    this.index = index
    this.emit('change:index')
    this.emit('change')
  }
  setPosition(x, y) {
    this.x = x
    this.y = y
    this.emit('change:position')
    this.emit('change')
  }
  requestFocus() {
    if (!this.manager) {
      throw new Error('Cannot focus a window that is not being managed')
    }
    this.manager.focus(this)
  }

  updateBounding(x, y) {
    if (x == null) x = window.innerWidth
    if (y == null) y = window.innerHeight
    console.log('updateBounding', x, this.width > x, this.width)
    if (this.x != 0 && this.x + this.width > x) {
      this.width = x - this.x
      this.emit('change:size')
      this.emit('change')
    }
    if (this.width > x && this.width > this.minWidth) {
      this.width = x
      this.emit('change:size')
      this.emit('change')
    }
  }

  mode = INACTIVE
  width = 250
  height = 400
  setSize(width, height) {
    this.width = width
    this.height = height
    this.emit('change:size')
    this.emit('change')
  }
  update(x, y) {
    // if (this.mode === MOVE) { return this._move(x, y) }
    if (this.mode === RESIZE) return this._resize(x, y)
  }
  startResize(x, y, direction, ref) {
    this.mode = RESIZE
    this._direction = direction // ['n','en','e','se','s','sw','w','nw']
    this._ref = ref
    this._quad = this._quadrant(x, y)
    this._startX = this.x
    this._startY = this.y
    this._startWidth = this.width
    this._startHeight = this.height
    this._originX = x
    this._originY = y
  }
  _resize(x, y) {
    var deltaX = x - this._originX
    var deltaY = y - this._originY
    
    var finalWidth = this._startWidth + (this._quad.left ? deltaX * -1 : deltaX)
    var finalHeight = this._startHeight + (this._quad.top ? deltaY * -1 : deltaY)
    
    // console.log('_resize', this._ref.offsetWidth , finalWidth, this.minWidth)
    if (finalWidth > this.maxWidth ) {
      deltaX = this.maxWidth - this._startWidth
      if (this._quad.left) { deltaX *= -1 }
    } else if (finalWidth < this.minWidth) {
      deltaX = this.minWidth - this._startWidth
      if (this._quad.left) { deltaX *= -1 }
    }

    if (finalHeight > this.maxHeight) {
      deltaY = this.maxHeight - this._startHeight
      if (this._quad.top) { deltaY *= -1 }
    } else if (finalHeight < this.minHeight) {
      deltaY = this.minHeight - this._startHeight
      if (this._quad.top) { deltaY *= -1 }
    }

    if (this._quad.left) {
      this.x = this._startX + deltaX
      this.width = this._startWidth - deltaX
    } else {
      if ([/*'ne',*/'e','se'].find(e => e === this._direction))
        this.width = this._startWidth + deltaX
    }

    if (this._quad.top) {
      this.y = this._startY + deltaY
      this.height = this._startHeight - deltaY
    } else {
      if (['se','s'].find(e => e === this._direction))
        this.height = this._startHeight + deltaY
    }
  }
  endChange() {
    // console.log('endChange')
    if (this.mode === INACTIVE) return
    const mode = this.mode
    this.mode = INACTIVE
    
    // console.log('end mode RESIZE')
    if (this._ref) {
      if (this._ref.offsetWidth > this.width)
        this.width = this._ref.offsetWidth
      // if (this._ref.offsetHeight > this.height)
      //   this.height = this._ref.offsetHeight
    }
    
    if (mode === MOVE) {
      delete this._offsetX
      delete this._offsetY
    }
    
    else if (mode === RESIZE) {
      delete this._quad
      delete this._startX
      delete this._startY
      delete this._startWidth
      delete this._startHeight
      delete this._originX
      delete this._originY
      this.emit('change:size')
    }
    
    this.emit('change:position')
    this.emit('change')
  }


  _quadrant(x, y) {
    return {
      top: y < this.y + (this.height / 2),
      left: x < this.x + (this.width / 2)
    }
  }

  on(event, cb) {
    this._event.addEventListener(event, cb)
  }
  off(event, cb) {
    this._event.removeEventListener(event, cb)
  }
  emit(event, props) {
    this._event.dispatchEvent(new Event(event, props))
  }
}




class WManager {
  _windows = {}
  _event = null
  _active = null
  _index = 2

  constructor() {
    this._event = new EventTarget()
  }

  on(event, cb) {
    this._event.addEventListener(event, cb)
  }
  off(event, cb) {
    this._event.removeEventListener(event, cb)
  }
  emit(event, props) {
    this._event.dispatchEvent(new Event(event, props))
  }

  has(window) {
    var id = window.id ? window.id : window
    return this._windows.hasOwnProperty(id)
  }
  get(id) {
    return this._windows[id]
  }
  add(window) {
    if (!(window instanceof Window)) { window = new Window(window) }
    window.manager = this

    this._windows[window.id] = window
    this.focus(window)

    window.on('change:open', () => {
      // console.log('change:open')
      this.emit('change')
    })

    window.on('change', () => {
      this.emit('change:windows')
    })

    this.emit('add', window)
    this.emit('change')

    return window
  }

  open(component, defaults) {
    if (! defaults) { defaults = {} }
    const id = defaults.id
    defaults.manager = this

    let window = null
    if (this.has(id)) window = this.get(id)
    else {
      window = this.add(defaults)
      window.setComponent(component)
    }
    window.open()
    this.focus(window)

    return window
  }

  _available = {}
  registerWindows(windows = []) {
    for (let _window of windows) {
      this._available[_window.type] = _window
      // const { component, ...props } = _window
      // this.add(_window)
    }
  }
  openByType(type, subtype) {
    const _window = this._available[type]
    if (!_window) throw new Error('no available window')
    const { component, ...props } = _window
    return this.open(component, { id: subtype != null ? `${type}:${subtype}` : type, code: subtype, ...props })
  }
  hasRegisteredType(window) {
    var type = window.type ? window.type : window
    return this._available.hasOwnProperty(type)
  }

  allWindows() {
    return Object.values(this._windows)
  }

  openWindows() {
    // console.log('openWindows', this)
    return this.allWindows().filter(function (window) {
      return window.isOpen
    })
  }

  focus(id) {
    var window = typeof id === 'object' ? id : this.get(id)

    if (! window) {
      throw new Error('Can not focus on a window it cannot find: ' + id)
    } else if (window === this._active) {
      // this window already has focus
      return
    }

    window.setIndex(this._index)
    this._index += 1
    this._active = window
    this.emit('change')
  }
}

export default WManager
