import FetchPromise from '../FetchAction'
import Actions from '../Actions'
import FetchAction from '../FetchAction'


export const KNOWLEDGE = {
  // SET_OPTION: 'scene/SET_OPTION',

  API_GET_KNOWLEDGE: 'api/GET_KNOWLEDGE',
  API_GET_KNOWLEDGE_SUCCESS: 'api/GET_KNOWLEDGE_SUCCESS',
  API_GET_KNOWLEDGE_FAILURE: 'api/GET_KNOWLEDGE_FAILURE',

}


class KnowledgeActions extends Actions {
  // API_SERVER_URL = process.env.NODE_ENV === 'development'
  //   ? 'http://localhost:3030/api/knowledge'
  //   : 'http://xenocide-duchunet.rhcloud.com/api/knowledge';
  API_SERVER_URL = __CONFIG.API_HOST + '/knowledge';

  exposed() {
    return {
      // toggleKnowledgeOption: this.toggleKnowledgeOption,
      getKnowledge: this.getKnowledge.bind(this),
    }
  }

  getKnowledge(model) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: '/' }),
        data: model,
        type: KNOWLEDGE.API_GET_KNOWLEDGE,
        method: 'GET',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }


  // toggleKnowledgeOption = (option_key, forced_value) => (dispatch, getState) => {
  //   return dispatch({
  //     type: KNOWLEDGE.SET_OPTION,
  //     payload: { [option_key]: forced_value },
  //   })
  // }
}

const KnowledgeActionsInstance = new KnowledgeActions()
export default KnowledgeActionsInstance.exposed()
