import React from 'react'
import { RaisedButton, Popover as PopoverMui, Menu, MenuItem } from 'material-ui'
import './Popover.scss'


class Popover extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
    };
  }

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  renderAnchor() {
    if (this.props.anchor) {
      return (
        <div className={'event_handler'} onClick={this.handleClick}>
          {this.props.anchor}
        </div>
      )
    }

    return (
      <RaisedButton
        onClick={this.handleClick}
        label="Click me"
      />
    )
  }
  render() {
    const className = ['popover_component']
    if (this.props.className) className.push(this.props.className)

    return (
      <div className={className.join(' ')}>
        {this.renderAnchor()}
        <PopoverMui
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{'horizontal':'left','vertical':'top'}}
          targetOrigin={{'horizontal':'left','vertical':'bottom'}}
          onRequestClose={this.handleRequestClose}
        >
          {this.props.children || (
            <Menu>
              <MenuItem primaryText="Refresh" />
              <MenuItem primaryText="Help &amp; feedback" />
              <MenuItem primaryText="Settings" />
              <MenuItem primaryText="Sign out" />
            </Menu>
          )}
        </PopoverMui>
      </div>
    )
  }
}


export default Popover
