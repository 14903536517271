import Actions from '../Actions'

const WMANAGER = {
  REGISTER_WINDOWS: 'wmanager/REGISTER_WINDOWS',
  OPEN_WINDOW: 'wmanager/OPEN_WINDOW',
  CLOSE_WINDOW: 'wmanager/CLOSE_WINDOW',
  RAISE_WINDOW: 'wmanager/RAISE_WINDOW',
  CLOSE_ALL_WINDOWS: 'wmanager/CLOSE_ALL_WINDOWS',
}
export { WMANAGER }

class WManagerActions extends Actions {
  exposed() {
    return {
      registerWindows: this.registerWindows,
      openWindow: this.openWindow,
      closeWindow: this.closeWindow,
      raiseWindow: this.raiseWindow,
      closeAllWindows: this.closeAllWindows,
    }
  }

  registerWindows = (model = {}) => (dispatch, getState) => {
    dispatch({
      type: WMANAGER.REGISTER_WINDOWS,
      payload: { ...model },
      // { id: 'b1', name: 'SceneSettings', open: true, props: { default: 'SceneSettings' } }
    })
  }

  openWindow = (model = {}) => (dispatch, getState) => {
    dispatch({
      type: WMANAGER.OPEN_WINDOW,
      payload: { id: this.uuid(), ...model },
      // { id: 'b1', name: 'SceneSettings', open: true, props: { default: 'SceneSettings' } }
    })
  }

  closeWindow = (windowId) => (dispatch, getState) => {
    dispatch({
      type: WMANAGER.CLOSE_WINDOW,
      payload: { id: windowId },
    })
  }

  raiseWindow = (windowId) => (dispatch, getState) => {
    dispatch({
      type: WMANAGER.RAISE_WINDOW,
      payload: { id: windowId },
    })
  }

  closeAllWindows = (model) => (dispatch, getState) => {
    dispatch({
      type: WMANAGER.CLOSE_ALL_WINDOWS,
    })
  }
}

const WManagerActionsInstance = new WManagerActions()
export default WManagerActionsInstance.exposed()
