import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
// import { Link } from 'react-router-dom'
// import { Grid, Row, Col } from 'react-flexbox-grid'
import Popover from '../../../components/Popover/Popover'
// import { Icon } from '../../../components/Icons'
import { Menu, MenuItem } from 'material-ui'
import AppActions from '../AppActions'
// import moment from 'moment'
import FromNow from '../../../components/FromNow/FromNow'
// import { FormattedMessage, injectIntl } from 'react-intl'

import SocketStatus from 'modules/socket/components/SocketStatus'

// import Graph from '../../../components/Charts/Graph/Graph'

import './AppSyncStatus.scss'

class AppSyncStatus extends React.Component {
  static defaultProps = {
    theorys: null,
    initialSync: () => {},
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.theorys && nextProps.theorys) {
      this.initialAuthSync()
    }
  }

  initialAuthSync() {
    this.props.initialSync()
  }

  getStatuses() {
    const { theorys, orders } = this.props
    const statuses = [
      {
        name: 'theorys',
        last_update: theorys && theorys.last_update,
        status: !theorys
          ? 'not ready'
          : !theorys.last_update
            ? 'not_sync'
            : 'sync',
      },
      {
        name: 'orders',
        last_update: orders && orders.last_update,
        status: !orders
          ? 'not ready'
          : !orders.last_update
            ? 'not_sync'
            : 'sync',
      }
    ]

    return statuses
  }

  renderStatuses() {
    const statuses = this.getStatuses()

    return statuses.map(s => (
      <MenuItem
        className={'row_status'}
        key={`status_of_${s.name}`}
        primaryText={s.name}
        secondaryText={(
          <span>
            {s.status} - <FromNow from={s.last_update} />
          </span>
        )}
      />
    ))
  }

  render() {
    const { theorys, unlocked } = this.props


    return (
      <Popover
        className={'app_sync_status_component'}
        anchor={(
          <SocketStatus tooltipPosition={'top-right'} />
        )}
        // anchor={(
        //   <Icon iconName={'sync_status'} />
        // )}
      >
        <Menu width={256}>
          {this.renderStatuses()}
        </Menu>
      </Popover>
    )
  }
}


// EXPORT ----------------------------------------------------------------------
export { AppSyncStatus }
// CONNECT TO REDUX
function mapStateToProps(state) {
  return {
    isAuthenticated: state.Auth && state.Auth.isAuthenticated,
    theorys: state.Theorys,
    orders: state.Orders,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AppActions }, dispatch)
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps, InjectedIntlProps)(AppSyncStatus))
