import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
// import WManagerActions from '../WManagerActions'
// import './DimensionIconButton.scss'
import { IconButton } from 'material-ui'
import Icon from '../../../components/Icons/Icon'
import SceneActions from '../SceneActions'


class DimensionIconButton extends React.PureComponent {
  static propTypes = {
    dimension: PropTypes.oneOf([ '2d', '3d' ]),
    onClick: PropTypes.func,
    tooltipPosition: PropTypes.string,
    switchDimension: PropTypes.func,
  }
  static defaultProps = {
    dimension: '3d',
    onClick: () => {},
  }

  // constructor(props, context) {
  //   super(props, context)
  //   this._onClick = this.onClick.bind(this)
  // }

  isActive() {
  //   return this.context.manager.has(this.props.windowId) ? this.context.manager.get(this.props.windowId).isOpen : false
  }

  onClick = (event) => {
    event.stopPropagation()
    this.props.switchDimension(this.props.dimension === '3d' ? '2d' : '3d')
    this.props.onClick()
  }

  render() {
    const { dimension } = this.props
    const is3d = dimension === '3d'
    return (
      <IconButton
        tooltip={is3d
          ? <FormattedMessage id='interface.switch_to_2d' defaultMessage='switch to 2D'/>
          : <FormattedMessage id='interface.switch_to_3d' defaultMessage='switch to 3D'/>}
        tooltipPosition={this.props.tooltipPosition}
        onClick={this.onClick}
      >
        <Icon iconName={is3d ? '3d' : '2d'} />
      </IconButton>
    )
  }
}

// export default DimensionIconButton
// CONNECT TO REDUX ------------------------------------------------------------
function mapStateToProps(state) {
  return {
    dimension: state.Scene && state.Scene.dimension,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...SceneActions }, dispatch)
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps, InjectedIntlProps)(DimensionIconButton))
