import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import AppReducer from 'modules/app/AppReducer'
import { SocketMiddleware } from 'modules/socket/SocketMiddleware'
import AuthMiddleware from 'modules/auth/AuthMiddleware'

let store = null
export default () => {
  // const initialState = {}
  const middlewares = [thunk, SocketMiddleware, AuthMiddleware]

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    // STORE LOGGER
    // eslint-disable-next-line no-undef
    const { createLogger } = require('redux-logger')
    const logger = createLogger({
      level: 'info',
      collapsed: true,
    })
    middlewares.push(logger)
  }

  store = createStore(createReducer(), compose(
    applyMiddleware(...middlewares)
  ))
  store.async = {}
  store.persistor = persistStore(store)

  return store
}

export function getStore() {
  return store
}

export function registerReducer(store, name, reducers = {}) {
  if (typeof reducers !== 'object') reducers = { [name]: reducers }
  // store.async[name] = reducer
  Object.assign(store.async, reducers)
  store.replaceReducer(createReducer(store.async))
  store.persistor.persist()
}

function createReducer (reducers) {
  return persistReducer({
    key: 'root',
    storage,
    whitelist: ['App'],
    // There is an issue in the source code of redux-persist (default setTimeout does not cleaning)
    timeout: null, 
  }, combineReducers({
    root: (state=null) => state,
    // Socket: (state={}) => state,
    App: AppReducer,
    ...reducers
  }))
}
