import FetchAction from './FetchAction'

// const API_SERVER_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3030/auth' : 'http://xenocide-duchunet.rhcloud.com/auth'
// const API_ACTIONS = Config.get('api.actions')


class Actions {
  // API_SERVER_URL = process.env.NODE_ENV === 'development'
  //   ? 'http://localhost:3030/auth'
  //   : 'http://xenocide-duchunet.rhcloud.com/auth';
  API_SERVER_URL = __CONFIG.API_HOST + '/auth';
  actionMap = {}
  request = {
    type: 'json',
    timeout: 15000,
  }

  export() {
    const actionMap = { ...this.actionMap }
    Object.keys(actionMap).forEach(key => actionMap[key].bind(this))
    return actionMap
  }

  fetch(route, type, method = 'GET', props = null, cb = null) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: route }),
        type: type,
        method: method,
        abortController: props != null ? props.abortController : undefined,
      }
      if (method != 'GET' && props != null) {
        fetchOptions.data = props.model || props
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
        .then(result => {
          if (cb) {
            const res = cb(dispatch)
            if (res) dispatch(res)
          }
          return result
        })
    }
  }

  createUrl(options) {
    const { type, route, params = {} } = options
    let url = `${this.API_SERVER_URL}/${route.startsWith('/') ? route.substring(1) : route}`
    if (url.endsWith('/')) url = url.substring(0, url.length-1)
    Object.keys(params).forEach(key => {
      url = url.replace(`:${key}`, params[key])
    })
    return url
  }

  readAction() {

  }
  createAction() {

  }
  updateAction() {

  }
  deleteAction() {

  }

  createParamsString(params) {
    const esc = encodeURIComponent
    const query = Object.keys(params)
      .map(k => `${esc(k)}=${esc(params[k])}`)
      .join('&')
    return query
  }

  fetchUrl(dispatch, getState, fetchOptions) { //uri, fetchData, options) {
    let { url } = fetchOptions
    const {
      method = 'POST',
      type, params, data,
    } = fetchOptions
    const state = getState()
    const { Auth } = state

    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
    if (Auth.isAuth && Auth.token) {
      headers['access-token'] = Auth.token
    }

    if (params) {
      url += `?${this.createParamsString(params)}`
    }
    console.log(url)

    const fetchPromise = fetch(url, {
      // ...fetchData,
      body: JSON.stringify(data),
      method: method,
      credentials: 'same-origin',
      headers: headers,
    })

    return new Promise.resolve()
      .then(() => {
        this.dispatchApiAction(dispatch, type, 'REQUEST')
        return true
      })
      .then(() => this.raceTimeout(fetchPromise))
      .then((response) => this.fetchResponse(response))
      .then((response) => {
        this.dispatchApiAction(dispatch, type, 'SUCCESS', response)
        return response
      })
      .catch((err) => {
        this.dispatchApiAction(dispatch, type, 'FAILURE', err)
        if (err instanceof TypeError) {
          throw new ApiError('connection')
        }
        throw err
      })
  }

  dispatchApiAction(dispatch, actionType, type, payload) {
    let responseType
    switch (type) {
      case 'REQUEST':
      case 'SUCCESS':
      case 'FAILURE':
        responseType = `${actionType}_${type}`
        break
      default: responseType = `${actionType}_FAILURE`
    }

    let addedToAction = {}
    switch (type) {
      case 'SUCCESS':
        addedToAction = { payload: payload }
        break
      case 'FAILURE':
        addedToAction = { error: payload, payload: payload }
        break
    }

    return dispatch({
      type: responseType,
      meta: { api: true },
      ...addedToAction,
    })
  }

  fetchResponse(response) {
    return Promise.resolve()
      .then(() => {
        if (response.ok) {
          const { type } = this.request
          return response[type]()
        }
        if (response.status) {
          // throw new FetchError(data)
          return this.apiError(response)
        }
        throw new ApiError('network')
      })
      // .catch(err => {
      //   console.log('>>> ', {...err})
      //   throw err
      // })
  }
  apiError(response) {
    return Promise.resolve()
      .then(() => response.json())
      .then((data) => { throw new FetchError(response.status, data) })
      .catch((err) => {
        console.log('apiError', err)
        throw err
      })
  }
  raceTimeout(promises) {
    if (!Array.isArray(promises)) promises = [promises]
    return new Promise.race([ this.timeout(), ...promises ])
  }
  timeout() {
    const { timeout } = this.request
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new ApiError('timeout')), timeout)
    })
  }

  uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0
      const v = c == 'x' ? r : (r&0x3|0x8)
      return v.toString(16)
    })
  }


  clearEventsReducer() {
    return {
      type: C.REDUCER_CLEAR_EVENTS,
    }
  }
}



class ApiError {
  static uxMessages = {
    timeout: 'serwer nie odpowiada',
    network: 'problem z siecią',
    connection: 'problem z połączeniem',
    UNDEFINED_ERROR: '_UNDEFINED_ERROR',
  }

  constructor(type) {
    this.name = 'ApiError'
    this.type = type || 'UNDEFINED_ERROR'
    // this.user = this.getUserMessage(type)
    // this.data = data
    // this.errors = data.errors
    // this.stack = (new Error()).stack
  }

  get userMessage() {
    return ApiError.uxMessages[this.type] || '_NO_MESSAGE'
  }

  toString() {
    return `${this.name}: ${this.message}`
  }
}

class FetchError {
  constructor(message, data) {
    this.name = 'FetchError'
    this.message = message || 'UNDEFINED ERROR'
    this.data = data
    this.errors = data.errors
    this.stack = (new Error()).stack
  }
  toString() {
    return `${this.name}: ${this.message}`
  }
}
// FetchError.prototype = new Error

export default Actions
