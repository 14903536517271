import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// localization
import { addLocaleData, IntlProvider } from 'react-intl'
import enLocaleData from 'react-intl/locale-data/en'
import plLocaleData from 'react-intl/locale-data/pl'
addLocaleData([...enLocaleData, ...plLocaleData])
import AppActions from 'modules/app/AppActions'


const messages = {
  en: {
    // 'main.coming_soon': 'coming soon'
  },
  pl: {
    'main.language': 'język',
    'main.email': 'email',
    'main.password': 'hasło',
    'main.retype_password': 'powtórz hasło',
    'main.process': 'przetwarzaj',
    'main.processing': 'przetwarzanie',
    'main.accept_terms': 'zaakceptuj warunki użytkowania',
    'main.register_success': 'użytkownik zarejestrowany z powodzeniem',
    'main.switch_to_window': 'przełącz do okna',
    'main.switch_to_fullscreen': 'przełącz do pełnego ekranu',
    'main.message': 'wiadomość',
    
    'interface.dashboard': 'dashboard',
    'interface.conversations': 'konwersacje',
    'interface.nation': 'naród',
    'interface.property': 'własność',
    'interface.switch_to_2d': 'przełącz do 2D',
    'interface.switch_to_3d': 'przełącz do 3D',
    'interface.theorys': 'teorie',
    'main.bug_report': 'zgłaszanie błędów',
    'main.coming_soon': 'już wkrótce',
    'main.connection_with_server': 'połączenie z serwerem: {status}',
    'main.credits': 'uznanie',
    'main.login': 'logowanie',
    'main.main_page': 'strona główna',
    'main.news': 'news',
    'main.register': 'rejestracja',
    'main.terms': 'warunki',
    'gallery.version.title': 'galeria wersji',
    'error.email_not_valid': 'email nieprawidłowy',
    'error.password_not_valid': 'musi zawierać między {min}, a {max} znaków',
    'error.retype_password_not_valid': 'powtórzone hasło musi byc identyczne z hasłem',
  }
}


class Intl extends React.PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    default: PropTypes.string,
    children: PropTypes.any.isRequired,
    switchLocale: PropTypes.func.isRequired,
  }
  static defaultProps = {
    default: 'en',
  }


  constructor(props, context) {
    super(props, context)

    if (props.locale == null) {
      const locale = props.locale || ['pl', 'en'].find(key => key == navigator.language.split(/[-_]/)[0]) 
        ? navigator.language.split(/[-_]/)[0] 
        : props.default
      props.switchLocale(locale)
    }
  }


  static childContextTypes = {
    setLocale: PropTypes.func,
  }
  getChildContext() {
    return {
      setLocale: this.setLocale,
    }
  }
  setLocale = (locale) => {
    // eslint-disable-next-line no-console
    if (!['pl', 'en'].find(key => key == locale)) return console.warn(`${locale} locale not found`)
    this.props.switchLocale(locale)
  }


  render() {
    const locale = this.props.locale || this.props.default
    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        {this.props.children}
      </IntlProvider>
    )
  }
}


// export default Intl
function mapStateToProps(state) {
  return { locale: state.App && state.App.locale }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(AppActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Intl)

