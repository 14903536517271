import { C } from './AuthActions'

const AuthMiddleware = store => next => action => {
  next(action)

  if (action.error 
    && action.error.data 
    && action.error.data.error 
    && action.error.data.error.token) {
    store.dispatch({ type: C.API_TOKEN_EXPIRED })
  }
}

export default AuthMiddleware
