import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class AuthenticatedWrapper extends React.PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
  }

  render() {
    if (!this.props.isAuthenticated) return null
    // if (Array.isArray(this.props.children)) return <div>{this.props.children.map(Child => <Child key={} />)}</div>
    return this.props.children
  }
}

export { AuthenticatedWrapper }
// CONNECT TO REDUX ------------------------------------------------------------
function mapStateToProps(state, props) {
  return { isAuthenticated: state.Auth && state.Auth.isAuthenticated }
}
export default connect(mapStateToProps)(AuthenticatedWrapper)
