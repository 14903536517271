import React from 'react'
import ReactDOM, { render } from 'react-dom'
import injectTapEventPlugin from 'react-tap-event-plugin'
try { injectTapEventPlugin() } catch (err) { console.error('err injectTapEventPlugin') }

import App from './App'

// import { createStore, combineReducers, applyMiddleware } from 'redux'
// import { Route } from 'react-router'
// import { Provider } from 'react-redux'
// import { ConnectedRouter, routerReducer, routerMiddleware, push } from 'react-router-redux'

// import './styles/app.scss';
// require('../node_modules/duchunet-web-client/node_modules/react-flexbox-grid/lib/index.css')
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
// import getMuiTheme from 'material-ui/styles/getMuiTheme'
// // import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme'
// import darkBaseTheme from './themes/dark_theme'
// import 'font-awesome-webpack'

// if (process.env.NODE_ENV !== 'production')
//   injectTapEventPlugin();

// import App from './App'

// import store from './store/store'
// import history from './store/history'


// ReactDOM.render(
//   <Provider store={store}>
//     { /* ConnectedRouter will use the store from Provider automatically */ }
//     <ConnectedRouter history={history}>
//       <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
//         <App />
//       </MuiThemeProvider>
//     </ConnectedRouter>
//   </Provider>,
//   document.getElementById('root')
// )
ReactDOM.render(
  <App />,
  document.getElementById('root')
)
