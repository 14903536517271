import React from 'react'
import PropTypes from 'prop-types'
// import TheorysWindow from 'containers/interface/windows/TheorysWindow'
// import ConversationsWindow from 'containers/interface/windows/ConversationsWindow'
// import DashboardWindow from 'containers/interface/windows/DashboardWindow'
// import NationDetails from 'modules/nations/components/NationDetails'

// const components = {
//   theorys: {
//     component: TheorysWindow,
//     options: {
//       title: `Theorys`,
//       id: 'theorys',
//       width: 900,
//       height: 500,
//     }
//   },
//   conversations: {
//     component: ConversationsWindow,
//     options: {
//       title: `Conversations`,
//       id: 'conversations',
//       width: 800,
//       height: 500,
//     }
//   },
//   nation: {
//     component: NationDetails,
//     options: {
//       title: `Nation`,
//       id: 'nation_settings',
//       width: 300,
//       // height: 500,
//     }
//   },
//   dashboard: {
//     component: DashboardWindow,
//     options: {
//       title: `Dashboard`,
//       id: 'dashboard',
//       width: 600,
//       // height: 500,
//     }
//   },
// }

// This function takes a component...
function withWManager(WrappedComponent, selectData) {
  // ...and returns another component...
  return class extends React.Component {
    static contextTypes = {
      manager: PropTypes.object,
    }

    constructor(props, context) {
      super(props, context)
      // this.handleChange = this.handleChange.bind(this)
      this.state = {
      //   data: selectData(DataSource, props)
      }
      this._handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
      // ... that takes care of the subscription...
      // DataSource.addChangeListener(this.handleChange)
      this.context.manager.on('change', this._handleChange)

    }

    componentWillUnmount() {
      // DataSource.removeChangeListener(this.handleChange)
      this.context.manager.off('change', this._handleChange)
    }

    handleChange() {
      // this.setState({
      //   data: selectData(DataSource, this.props)
      // })
      this.forceUpdate()
    }

    isActive(windowId) {
      if (!windowId) return false
      return this.context.manager.has(windowId) ? this.context.manager.get(windowId).isOpen : false
    }

    onClick() {
      const system = {}
      // const component = components[this.props.windowId]
      // if (!component) return console.warn('no component');
      console.log(this.props.type, this.props.code);
      this.context.manager.openByType(this.props.type, this.props.code)
      // this.context.manager.open(<component.component windowId={this.props.windowId} data={this.props.data} />, {
      //   // title: `${system.name} System`,
      //   // id: system.code
      //   ...component.options
      // })
    }



    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <WrappedComponent
        isActive={this.isActive(this.props.windowId)}
        onClick={this.onClick.bind(this)}
        {...this.props}
      />
    }
  }
}

export default withWManager
