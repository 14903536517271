import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './WindowsManagerLayout.scss'
// import BasicWindow from './BasicWindow'
// import CreditsWindow from './CreditsWindow'
// import DraggableWindow from './DraggableWindow'
import WManagerActions from '../../modules/WManager/WManagerActions'


class WindowsManagerLayout extends React.Component {
  static propTypes = {
    manager: PropTypes.object.isRequired,
    children: PropTypes.any,
  }
  static defaultProps = { }

  constructor(props, context) {
    super(props, context)
    this.manager = this.props.manager
  }

  componentDidMount() {
    // this.manager = this.props.manager
    // this.manager.on('change', this.forceUpdate, this)
    this.manager.on('change', () => {
      // console.warn('UPDATE');
      this.forceUpdate()
    })
  }

  state = { }


  render() {
    const { manager, children } = this.props
    // console.warn('render WindowsManagerLayout')

    var windows = manager.openWindows().map((window, index) => {
      // console.log(window);
      // return new Window({
      //   key: window.id,
      //   offset: this.state.offset,
      //   window: window,
      // });
      return (
        <window.component 
          key={`${window.id}-${index}`}
          window={window}
          open={true}
          code={window.code}
        />
      )
    }, this)


    return (
      <div className={'layout window_manager_layout'} key={'window_manager_layout'}>
        <div className={'windows_overlay'}>
          {/* {this.renderWindows()} */}
          {windows}
        </div>
        {children}
      </div>
    )
  }
}


export { WindowsManagerLayout }
// CONNECT TO REDUX ------------------------------------------------------------
function mapStateToProps(state) {
  return {
    app: state.App,
    active_windows: state.WManager && state.WManager.active_windows,
    registered_windows: state.WManager && state.WManager.registered_windows,
    // scene: state.Scene,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...WManagerActions, dispatch: action => action }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(WindowsManagerLayout)
