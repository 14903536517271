// import FetchPromise from '../FetchAction'
import Actions from '../Actions'
import FetchAction from '../FetchAction'


export const WORLD = {
  // SET_OPTION: 'scene/SET_OPTION',

  API_GET_BOOTUP_WORLD: 'api/GET_BOOTUP_WORLD',
  API_GET_BOOTUP_WORLD_SUCCESS: 'api/GET_BOOTUP_WORLD_SUCCESS',
  API_GET_BOOTUP_WORLD_FAILURE: 'api/GET_BOOTUP_WORLD_FAILURE',
  // API_GET_USER_NATION: 'api/GET_USER_NATION',
  // API_GET_USER_NATION_SUCCESS: 'api/GET_USER_NATION_SUCCESS',
  // API_GET_USER_NATION_FAILURE: 'api/GET_USER_NATION_FAILURE',
  API_GET_SYSTEM: 'api/GET_SYSTEM',
  API_GET_SYSTEM_SUCCESS: 'api/GET_SYSTEM_SUCCESS',
  API_GET_SYSTEM_FAILURE: 'api/GET_SYSTEM_FAILURE',
  API_BUILD_ON_CELESTIAL: 'api/BUILD_ON_CELESTIAL',
  API_BUILD_ON_CELESTIAL_SUCCESS: 'api/BUILD_ON_CELESTIAL_SUCCESS',
  API_BUILD_ON_CELESTIAL_FAILURE: 'api/BUILD_ON_CELESTIAL_FAILURE',
  API_LEARN_KNOWLEDGE: 'api/LEARN_KNOWLEDGE',
  API_LEARN_KNOWLEDGE_SUCCESS: 'api/LEARN_KNOWLEDGE_SUCCESS',
  API_LEARN_KNOWLEDGE_FAILURE: 'api/LEARN_KNOWLEDGE_FAILURE',
  API_UPDATE_EFFICIENCY: 'api/UPDATE_EFFICIENCY',
  API_UPDATE_EFFICIENCY_SUCCESS: 'api/UPDATE_EFFICIENCY_SUCCESS',
  API_UPDATE_EFFICIENCY_FAILURE: 'api/UPDATE_EFFICIENCY_FAILURE',

  API_GET_WORLDS: 'api/GET_WORLDS',
  API_GET_WORLDS_SUCCESS: 'api/GET_WORLDS_SUCCESS',
  API_GET_WORLDS_FAILURE: 'api/GET_WORLDS_FAILURE',
  API_GET_GALAXIES: 'api/GET_GALAXIES',
  API_GET_GALAXIES_SUCCESS: 'api/GET_GALAXIES_SUCCESS',
  API_GET_GALAXIES_FAILURE: 'api/GET_GALAXIES_FAILURE',
  API_GET_SYSTEMS: 'api/GET_SYSTEMS',
  API_GET_SYSTEMS_SUCCESS: 'api/GET_SYSTEMS_SUCCESS',
  API_GET_SYSTEMS_FAILURE: 'api/GET_SYSTEMS_FAILURE',
  API_GET_CELESTIALS: 'api/GET_CELESTIALS',
  API_GET_CELESTIALS_SUCCESS: 'api/GET_CELESTIALS_SUCCESS',
  API_GET_CELESTIALS_FAILURE: 'api/GET_CELESTIALS_FAILURE',
  API_GET_REGIONS: 'api/GET_REGIONS',
  API_GET_REGIONS_SUCCESS: 'api/GET_REGIONS_SUCCESS',
  API_GET_REGIONS_FAILURE: 'api/GET_REGIONS_FAILURE',

  API_DELETE_WORLD: 'api/DELETE_WORLD',
  API_DELETE_WORLD_SUCCESS: 'api/DELETE_WORLD_SUCCESS',
  API_DELETE_WORLD_FAILURE: 'api/DELETE_WORLD_FAILURE',
  API_DELETE_GALAXIE: 'api/DELETE_GALAXIE',
  API_DELETE_GALAXIE_SUCCESS: 'api/DELETE_GALAXIE_SUCCESS',
  API_DELETE_GALAXIE_FAILURE: 'api/DELETE_GALAXIE_FAILURE',
  API_DELETE_SYSTEM: 'api/DELETE_SYSTEM',
  API_DELETE_SYSTEM_SUCCESS: 'api/DELETE_SYSTEM_SUCCESS',
  API_DELETE_SYSTEM_FAILURE: 'api/DELETE_SYSTEM_FAILURE',
  API_DELETE_CELESTIAL: 'api/DELETE_CELESTIAL',
  API_DELETE_CELESTIAL_SUCCESS: 'api/DELETE_CELESTIAL_SUCCESS',
  API_DELETE_CELESTIAL_FAILURE: 'api/DELETE_CELESTIAL_FAILURE',

  API_CREATE_WORLD: 'api/CREATE_WORLD',
  API_CREATE_WORLD_SUCCESS: 'api/CREATE_WORLD_SUCCESS',
  API_CREATE_WORLD_FAILURE: 'api/CREATE_WORLD_FAILURE',

  API_GENERATE_WORLD: 'api/GENERATE_WORLD',
  API_GENERATE_WORLD_SUCCESS: 'api/GENERATE_WORLD_SUCCESS',
  API_GENERATE_WORLD_FAILURE: 'api/GENERATE_WORLD_FAILURE',
  API_GENERATE_GALAXY: 'api/GENERATE_GALAXY',
  API_GENERATE_GALAXY_SUCCESS: 'api/GENERATE_GALAXY_SUCCESS',
  API_GENERATE_GALAXY_FAILURE: 'api/GENERATE_GALAXY_FAILURE',
  API_GENERATE_SYSTEM: 'api/GENERATE_SYSTEM',
  API_GENERATE_SYSTEM_SUCCESS: 'api/GENERATE_SYSTEM_SUCCESS',
  API_GENERATE_SYSTEM_FAILURE: 'api/GENERATE_SYSTEM_FAILURE',
  API_GENERATE_CELESTIAL: 'api/GENERATE_CELESTIAL',
  API_GENERATE_CELESTIAL_SUCCESS: 'api/GENERATE_CELESTIAL_SUCCESS',
  API_GENERATE_CELESTIAL_FAILURE: 'api/GENERATE_CELESTIAL_FAILURE',
}


class WorldActions extends Actions {
  // API_SERVER_URL = process.env.NODE_ENV === 'development'
  //   ? 'http://localhost:3030/api/world'
  //   : 'http://xenocide-duchunet.rhcloud.com/api/world';
  API_SERVER_URL = __CONFIG.API_HOST + '/world';

  // fetch(route, type, method = 'GET', props = {}) {
  //   return (dispatch, getState) => {
  //     const api = new FetchAction()
  //     const fetchOptions = {
  //       url: this.createUrl({ route: route }),
  //       type: type,
  //       method: method,
  //     }
  //
  //     return Promise.resolve()
  //       .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
  //   }
  // }

  exposed() {
    return {
      updateEfficiency: this.updateEfficiency.bind(this),
      buildOnCelestial: this.buildOnCelestial.bind(this),
      learnOnCelestial: this.learnOnCelestial.bind(this),
      bootupWorld: this.bootupWorld.bind(this),
      getSystem: this.getSystem.bind(this),
      // getUserWorld: this.getUserWorld.bind(this),
      getWorlds: props => this.fetch('/', WORLD.API_GET_WORLDS, 'GET', props).bind(this),
      getGalaxies: props => this.fetch('/galaxy', WORLD.API_GET_GALAXIES, 'GET', props).bind(this),
      getSystems: props => this.fetch('/system', WORLD.API_GET_SYSTEMS, 'GET', props).bind(this),
      getCelestials: props => this.fetch('/celestial', WORLD.API_GET_CELESTIALS, 'GET', props).bind(this),
      getRegions: props => this.fetch('/region', WORLD.API_GET_REGIONS, 'GET', props).bind(this),

      deleteWorld: props => this.fetch(`/${props.code}`, WORLD.API_DELETE_WORLD, 'DELETE', props, () => this.exposed().getWorlds()).bind(this),
      deleteGalaxy: props => this.fetch(`/galaxy/${props.code}`, WORLD.API_DELETE_GALAXIE, 'DELETE', props, () => this.exposed().getGalaxies()).bind(this),
      deleteSystem: props => this.fetch(`/system/${props.code}`, WORLD.API_DELETE_SYSTEM, 'DELETE', props, (dispatch) => {
        dispatch(this.exposed().getSystems())
        dispatch(this.exposed().getCelestials())
        dispatch(this.exposed().getRegions())
      }).bind(this),
      deleteCelestial: props => this.fetch(`/celestial/${props.code}`, WORLD.API_DELETE_CELESTIAL, 'DELETE', props, (dispatch) => {
        dispatch(this.exposed().getCelestials())
        dispatch(this.exposed().getRegions())
      }).bind(this),

      createWorld: props => this.fetch('/', WORLD.API_CREATE_WORLD, 'POST', props, () => this.exposed().getWorlds()).bind(this),

      generateWorld: props => this.fetch(`/${props.code}/generate`, WORLD.API_GENERATE_WORLD, 'GET', props, (dispatch) => {
        dispatch(this.exposed().getWorlds())
        dispatch(this.exposed().getGalaxies())
      }).bind(this),
      generateGalaxy: props => this.fetch(`/galaxy/${props.code}/generate`, WORLD.API_GENERATE_GALAXY, 'GET', props, (dispatch) => {
        dispatch(this.exposed().getGalaxies())
        dispatch(this.exposed().getSystems())
      }).bind(this),
      generateSystem: props => this.fetch(`/system/${props.code}/generate`, WORLD.API_GENERATE_SYSTEM, 'GET', props, (dispatch) => {
        dispatch(this.exposed().getSystems())
        dispatch(this.exposed().getCelestials())
      }).bind(this),
      generateCelestial: props => this.fetch(`/celestial/${props.code}/generate`, WORLD.API_GENERATE_CELESTIAL, 'GET', props, (dispatch) => {
        dispatch(this.exposed().getCelestials())
        dispatch(this.exposed().getRegions())
        // dispatch(this.exposed().getGalaxies())
      }).bind(this),
    }
  }

  updateEfficiency(props) {
    const { code, ...model } = props
    // {{host}}/api/world/celestials/{{capital_code}}/build
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: `/celestials/${code}/efficiency` }),
        data: model,
        type: WORLD.API_UPDATE_EFFICIENCY,
        method: 'POST',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  buildOnCelestial(props) {
    const { code, ...model } = props
    // {{host}}/api/world/celestials/{{capital_code}}/build
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: `/celestials/${code}/build` }),
        data: model,
        type: WORLD.API_BUILD_ON_CELESTIAL,
        method: 'POST',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  learnOnCelestial(props) {
    const { code, ...model } = props
    // {{host}}/api/world/celestials/{{capital_code}}/build
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: `/celestials/${code}/learn` }),
        data: model,
        type: WORLD.API_LEARN_KNOWLEDGE,
        method: 'POST',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  bootupWorld(model) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: '/bootup' }),
        data: model,
        type: WORLD.API_GET_BOOTUP_WORLD,
        method: 'GET',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  getSystem(model) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: `/systems/${model.code}` }),
        // data: model,
        type: WORLD.API_GET_SYSTEM,
        method: 'GET',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  // getUserWorld(model) {
  //   return (dispatch, getState) => {
  //     const api = new FetchAction()
  //     const fetchOptions = {
  //       url: this.createUrl({ route: `/user/${getState().Auth.user.id}` }),
  //       data: model,
  //       type:_BOOTUP WORLD.API_GET_USER_NATION,
  //       method: 'GET',
  //     }
  //
  //     return Promise.resolve()
  //       .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
  //   }
  // }
}

const WorldActionsInstance = new WorldActions()
export default WorldActionsInstance.exposed()
