import FetchPromise from '../FetchAction'
import Actions from '../Actions'
import FetchAction from '../FetchAction'


export const NATIONS = {
  // SET_OPTION: 'scene/SET_OPTION',

  API_GET_NATIONS: 'api/GET_NATIONS',
  API_GET_NATIONS_SUCCESS: 'api/GET_NATIONS_SUCCESS',
  API_GET_NATIONS_FAILURE: 'api/GET_NATIONS_FAILURE',
  API_GET_USER_NATION: 'api/GET_USER_NATION',
  API_GET_USER_NATION_SUCCESS: 'api/GET_USER_NATION_SUCCESS',
  API_GET_USER_NATION_FAILURE: 'api/GET_USER_NATION_FAILURE',
}


class NationActions extends Actions {
  // API_SERVER_URL = process.env.NODE_ENV === 'development'
  //   ? 'http://localhost:3030/api/nations'
  //   : 'http://xenocide-duchunet.rhcloud.com/api/nations';
  API_SERVER_URL = __CONFIG.API_HOST + '/nation';

  exposed() {
    return {
      // toggleNationOption: this.toggleNationOption,
      getNations: this.getNations.bind(this),
      getUserNation: this.getUserNation.bind(this),
    }
  }

  getNations(model) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        url: this.createUrl({ route: '/' }),
        data: model,
        type: NATIONS.API_GET_NATIONS,
        method: 'GET',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  getUserNation(model) {
    return (dispatch, getState) => {
      const api = new FetchAction()
      const fetchOptions = {
        // url: this.createUrl({ route: `/user/${getState().Auth.user.id}` }),
        url: this.createUrl({ route: '/user' }),
        data: model,
        type: NATIONS.API_GET_USER_NATION,
        method: 'GET',
      }

      return Promise.resolve()
        .then(() => api.fetchUrl(dispatch, getState, fetchOptions))
    }
  }

  // toggleNationOption = (option_key, forced_value) => (dispatch, getState) => {
  //   return dispatch({
  //     type: NATIONS.SET_OPTION,
  //     payload: { [option_key]: forced_value },
  //   })
  // }
}

const NationActionsInstance = new NationActions()
export default NationActionsInstance.exposed()
