import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './Progress.scss'
import { CircularProgress } from 'material-ui' //or DropDownMenu

const POSITION_TOP = 'top'
const POSITION_BOTTOM = 'bottom'
const POSITION_CENTER = 'center'

class Progress extends React.PureComponent {
  static propTypes = {
    // locales: PropTypes.array
    show: PropTypes.bool,
  }
  static defaultProps = {
    show: true,
    size: 100,
    text: true,
    top: false,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      // show: props.show,
    }
  }

  render() {
    const {
      show, text, subtext,
      noCircuit, size, children, noPadding, noLoadingOpacity,
      top,
      style
    } = this.props

    let isCenter = true
    if (top) isCenter = false

    const newStyle = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      // width: 200,
      // height: 300,
      textAlign: 'center',
      zIndex: 100,
      overflow: 'hidden',
      // padding: !noPadding ? 50 : 0,
      pointerEvents: noLoadingOpacity ? 'none' : 'initial',
      // background: colors.fade('rgb(255,255,255)', 0.9),
    }

    const renderPrimaryText = () => (
      <div className={'primary_text'}><code>{(typeof text !== 'boolean') ? text : 'loading'}</code></div>
    )
    const renderSecondaryText = () => (
      <div>
        {(typeof subtext !== 'boolean') ? subtext : 'loading from api'}
      </div>
    )
    const renderLoader = () => (
      <div style={newStyle} className={isCenter && 'aligner'}>
        <div>
          {!noCircuit && <CircularProgress size={size} />}
          {text && renderPrimaryText()}
          {subtext && renderSecondaryText()}
        </div>
      </div>
    )

    let childrenOpacity = 1
    if (show) childrenOpacity = 0.1
    if (noLoadingOpacity) childrenOpacity = 1

    return (
      <div
        className="circular_progress"
        style={{ position: 'relative', width: '100%', height: '100%' }}
      >
        {show && renderLoader()}
        {/* <div style={{ opacity: childrenOpacity, width: '100%', height: '100%' }}>
          {children}
        </div> */}
      </div>
    )
  }

}

// -------------------Connect Component to the Redux Store----------------------
function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(Progress)
