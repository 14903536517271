import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Router, Route, Switch } from 'react-router-dom'
import createHistory from 'history/createHashHistory'
const history = createHistory()

// localization
import Intl from './Intl'
// import { addLocaleData, IntlProvider } from 'react-intl'
// import enLocaleData from 'react-intl/locale-data/en'
// import plLocaleData from 'react-intl/locale-data/pl'
// addLocaleData([...enLocaleData, ...plLocaleData])
// addLocaleData({
//   locale: 'en-UPPER',
//   parentLocale: 'en',
// })
// const { locale, messages } = window.App


import createStore from './store/store'
import module from './module'


import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles'
import darkBaseTheme from './themes/dark_theme'
import MainLayout from './components/Layouts/MainLayout'
import AsyncComponent from './components/AsyncComponent'

import './styles/main.scss'
// import './styles/fonts/xenocide-icon-collection/_flaticon.scss'

const store = createStore()
const registerModule = module(store)
const Home = () => registerModule(' ', import(/* webpackChunkName: "home" */ './containers/home/index'))
const Interface = () => registerModule('interface', import(/* webpackChunkName: "interface" */ './containers/interface/index'))
if (__CONFIG.ADMIN_INTERFACE) {
  // var TO DECLARE IN WHOLE FILE
  var Mode = () => registerModule('mode', import(/* webpackChunkName: "admin" */ './containers/mode/index'))
  var Admin = () => registerModule('admin', import(/* webpackChunkName: "admin" */ './containers/admin/index'))
}
// const contact = () => register('contact', import(/* webpackChunkName: "contact" */ './modules/contact/index'))

class App extends React.PureComponent {
  static childContextTypes = {
    muiTheme: PropTypes.object,
  }

  getChildContext() {
    return {
      muiTheme: this.muiTheme,
    }
  }

  constructor(props, context) {
    super(props, context)
    this.muiTheme = getMuiTheme(darkBaseTheme)
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <Intl>
            <MuiThemeProvider muiTheme={this.muiTheme}>
              <Router history={history}>
                <MainLayout>
                  <Switch>
                    {__CONFIG.ADMIN_INTERFACE && [
                      <Route key={'mode'} path={'/mode'} component={({match}) => <AsyncComponent isAuth isAdmin moduleProvider={Mode} match={match} />} />,
                      <Route key={'admin'} path={'/admin'} component={({match}) => <AsyncComponent isAuth isAdmin moduleProvider={Admin} match={match} />} />
                    ]}
                    <Route path={'/interface'} component={({match}) => <AsyncComponent isAuth moduleProvider={Interface} match={match} />} />
                    <Route path={'*'} component={({match}) => <AsyncComponent moduleProvider={Home} match={match} />} />
                  </Switch>
                </MainLayout>
              </Router>
            </MuiThemeProvider>
          </Intl>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
