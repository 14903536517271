import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'

import { IconButton } from 'material-ui'
import WifiTetheringIcon from 'material-ui/svg-icons/device/wifi-tethering'
import PortableWifiOffIcon from 'material-ui/svg-icons/communication/portable-wifi-off'

import { C } from '../SocketActions'


//##############################################################################
class SocketStatus extends React.Component {
  static propTypes = {
    socket: PropTypes.object,
    tooltipPosition: PropTypes.string,
  }
  constructor(props, context) {
    super(props, context)
    this.state = {
      liveServerTime: null,
    }

    // this.socketClient = new SocketClient()
    // this.socketClient.connect()
  }
  // componentWillReceiveProps(nextProps) {
  //   if (!this.delta && nextProps.websocket.serverTimeDelta) {
  //     this.delta = nextProps.websocket.serverTimeDelta
  //     this.timeInterval = setInterval(() => {
  //       if (this) this.setState({ liveServerTime: dates.getDeltaDate(this.delta) })
  //     }, 1000)
  //   }
  // }
  // componentWillUnmount() {
  //   clearInterval(this.timeInterval)
  // }

  getStatus() {
    switch (this.props.socket.status) {
      case C.WEBSOCKET_CONNECTED: return 'online'
      case C.WEBSOCKET_DISCONNECTED:
      default:
        return 'offline'
    }
  }
  getStatusColor() {
    // const status = 'offline'
    switch (this.getStatus()) {
      case 'online': return 'green'
      case 'offline':
      default: return 'red'
    }
  }

  renderIconByStatus(status) {
    switch (status) {
      case 'online': return <WifiTetheringIcon color={this.getStatusColor()} />
      case 'offline':
      default: return <PortableWifiOffIcon color={this.getStatusColor()} />
    }
  }

  //############################################################################
  render() {
    const status = this.getStatus()
    // const { websocket } = this.props
    // const { liveServerTime } = this.state
    // const {
    //   status,
    //   ping,
    //   lastPing,
    //   pingHistory,
    //   serverTime,
    //   serverTimeUpdate,
    //   serverTimeDelta,
    //   universumTime
    // } = websocket
    //
    // let statusColor = 'red'
    // let statusSimple = 'offline'
    //
    // if (status === C.WEBSOCKET_CONNECTED) {
    //   statusColor = 'green'
    //   statusSimple = 'online'
    // }
    // if (status === C.WEBSOCKET_DISCONNECTED) {
    //   statusColor = 'red'
    //   statusSimple = 'offline'
    // }
    //
    // const maxWidth = { width: '100%' }
    // const displayNone = { display: 'none' }
    // const expand = this.state.expand
    // const className = { className: 'margin-vert5' }

    return (
      <IconButton 
        tooltip={(
          <FormattedMessage 
            id='main.connection_with_server' 
            defaultMessage='connection with server: {status}'
            values={{ status }}
          />
        )} 
        tooltipPosition={this.props.tooltipPosition}
      >
        {this.renderIconByStatus(status)}
      </IconButton>
    )
  }
}


// -------------------Connect Component to the Redux Store----------------------
function mapStateToProps(state) {
  return {
    socket: state.Socket || {},
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps, InjectedIntlProps)(SocketStatus))
