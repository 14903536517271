import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import FullscreenIcon from 'material-ui/svg-icons/navigation/fullscreen'
import FullscreenExitIcon from 'material-ui/svg-icons/navigation/fullscreen-exit'


export function toggleFullScreen () {
  if (!fullscreenEnabled()) {
    // ENABLE FULLSCREEN MODE
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen()
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen()
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen()
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    }
    return true
  } else {
    // DISABLE FULLSCREEN MODE
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
    return false
  }
}

export function fullscreenEnabled() {
  return !(
    !document.fullscreenElement
    && !document.mozFullScreenElement
    && !document.webkitFullscreenElement
    && !document.msFullscreenElement
  )
}

export function isFullscreen() {
  return 1 >= outerHeight - innerHeight
}


class FullscreenIconButton extends React.Component {
  static propTypes = {
    tooltipPosition: PropTypes.string,
  }
  static defaultProps = { }

  constructor(props, context) {
    super(props, context)
    this.state = {
      isFullscreen: isFullscreen(),
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }


  onResize = () => {
    this.setState({ isFullscreen: isFullscreen() })
  }
  onToggleFullscreen = () => {
    toggleFullScreen()
  }


  render() {
    const { isFullscreen } = this.state

    return (
      <IconButton
        className={`icon_button ${isFullscreen ? 'active' : ''}`}
        onClick={this.onToggleFullscreen}
        tooltip={isFullscreen 
          ? <FormattedMessage id='main.switch_to_window' defaultMessage='switch to window' /> 
          : <FormattedMessage id='main.switch_to_fullscreen' defaultMessage='switch to fullscreen' />
        }
        tooltipPosition={this.props.tooltipPosition}
      >
        {isFullscreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
      </IconButton>
    )
  }
}


export default FullscreenIconButton
