const quotes = [
  {
    quote: {
      pl: 'To jeszcze nie koniec. To nawet nie jest początek końca. Prawdopodobnie to koniec początku.',
      en: 'Now this is not the end. It is not even the be­gin­ning of the end. But it is, perhaps, the end of the be­gin­ning.'
    },
    author: { en: 'Sir Winston Churchill' }
  }, {
    quote: {
      pl: 'Czasami myślę, że jesteśmy we wszechświecie sami, czasami wręcz przeciwnie. Obie te myśli są niepokojące.',
      en: 'Sometimes I think we’re alone in the universe and sometimes I think we’re not. In either case the idea is quite staggering.'
    },
    author: { en: 'Arthur C. Clarke' }
  }, {
    quote: {
      pl: 'Nigdy nie podróżuj z pustymi rękami. Podróż bez zysku to podróż bez celu.',
      en: 'Never travel empty. A journey without profit is a journey with no purpose.'
    },
    author: { en: 'N.N.' }
  }, {
    quote: {
      pl: 'Twierdzenie, że Ziemia jest jedyną zamieszkaną planetą w nieskończonej przestrzeni, jest równie absurdalne, jak przekonanie, że na całym polu prosa wyrośnie tylko jedno ziarnko.',
      en: 'To consider the Earth as the only populated world in infi nite space is as absurd as to assert that in an entire field of millet, only one grain will grow'
    },
    author: { pl:'Metrodorus z Chios (400 p.n.e.)', en: 'Metrodorus of Chios (400 p.n.e.)' }
  }, {
    quote: {
      pl: 'Większość ludzi woli zginąć niż pomyśleć. W rzeczywistości tak się właśnie dzieje.',
      en: 'Most people would sooner die than think; in fact, they do so'
    },
    author: { en: 'Bertrand Russell (1872-1970)' }
  }, {
    quote: {
      pl: 'Kim jestem? Sumą doświadczeń... Wczoraj, dziś, jutro... Każdego dnia czymś więcej.',
      en: 'Who am I? I am but the sum of my experiences... and today I am more than I was yesterday and less than I will be tomorrow.'
    },
    author: { en: 'Terrabyte' }
  }, {
    quote: {
      pl: 'Nie mów mi, że to nie jest miejsce dla człowieka. Miejsce człowieka jest tam, dokąd się udaje – z pewnością poradzi sobie, kiedy dotrze na miejsce.',
      en: 'Don’t tell me that man doesn’t belong out there. Man belongs wherever he wants to go - and he’ll do plenty well when he gets there'
    },
    author: { en: 'Werner von Braun' }
  }, {
    quote: {
      pl: 'Nie ma zysku bez przyjemności.',
      en: 'No profit grows where there is no pleasure taken.',
    },
    author: { en: 'William Shakespeare' }
  }, {
    quote: {
      pl: 'Jeżeli potrafisz to sobie wyobrazić, możesz to zdobyć. Jeżli możesz o tym marzyć, możesz się tym stać.',
      en: 'If you can imagine it, you can achieve it. If you can dream it, you can become it'
    },
    author: { en: 'William Arthur Ward' }
  }, {
    quote: {
      pl: 'Nie jesteś bogaty, dopóki nie zdobędziesz czegoś, czego nie można kupić.',
      en: 'You’re not rich until you have something that money can’t buy.'
    },
    author: { en: 'Garth Brooks' }
  }, {
    quote: {
      pl: 'Jeżeli dwa statki nie wystarczają, sprowadź dziesięć.',
      en: 'If two ships are enough to do the job; bring ten'
    },
    author: { en: 'Steel' }
  }, {
    quote: {
      pl: 'Obserwując siebie, dochodzę do wniosku, że zdolność fantazjowania była znacznie ważniejsza niż mój talent do absorbowania pozytywnej wiedzy.',
      en: 'When I examine myself and my methods of thought, I come to the conclusion that the gift of fantasy has meant more to me than my talent for absorbing positive knowledge'
    },
    author: { en: 'Albert Einstein' }
  }, {
    quote: {
      pl: 'Nie wiem, kto rozpoczął walkę, ale wiem, kto ją skończył.',
      en: 'I don’t want to know who started a fight but I know who ended it.'
    },
    author: { en: 'BurnIt!' }
  }, {
    quote: {
      pl: 'Widziałem rzeczy, którym wy ludzie nie dalibyście wiary. Statki szturmowe w ogniu sunące ku ramionom Oriona. Oglądałem promienie kosmiczne błyszczące w ciemnościach blisko wrót Tannhausera. Wszystkie te chwile znikną w czasie jak łzy na deszczu. Czas umrzeć.',
      en: 'I’ve seen things, you people wouldn’t believe, hmmm. Attack ships on fi re off the shoulder of Orion. I’ve watched C Beams glitter in the dark near the Tannhauser Gate. All those moments, will be lost in time like tears in rain. Time to die.'
    },
    author: { en: 'Blade Runner - Vangelis - Tears in Rain' }
  }, {
    quote: {
      pl: 'Śmiało dążyć tam, gdzie nie dotarł jeszcze żaden człowiek.',
      en: 'To boldly go where no man has gone before'
    },
    author: { en: 'Zefram Cochrane - Star Trek' }
  }, {
    quote: {
      pl: 'Dziś jest dobry dzień na śmierć.',
      en: 'Today is a good day to die.'
    },
    author: { en: 'Worf - Star Trek' }
  }, {
    quote: {
      pl: '– Jaki kurs, kapitanie?\n– Druga gwiazda po prawej i lecimy aż do rana.',
      en: 'Course heading, Captain?\n- Second star to the right and straight on till morning.'
    },
    author: { en: 'Chekov & Kirk - Star Trek' }
  }, {
    quote: {
      pl: 'Jest jedna rzecz, którą wiemy o ludziach na pewno: są panami samozniszczenia.',
      en: 'If there`s one thing we know about human beings with certainty: they are masters of self-destruction.'
    },
    author: { en: 'Battlestar Galactica' }
  }, {
    quote: {
      pl: 'By w pełni żyć, musimy umrzeć i nie powrócić. Skaza ludzkości, nad którą całe życie ubolewacie, śmiertelność, jest jedyną rzeczą, która powoduje, że jesteście pełni.',
    },
    author: { en: 'Battlestar Galactica' }
  }, {
    quote: {
      pl: 'Chodzi o to, co powiedział pan na ceremonii. Ludzkość jest słabym tworem i że ludzie nadal się zabijają z zazdrości i chciwości. Mówił pan, że ludzkość nigdy nie spytała, dlaczego zasługuje na przetrwanie. Może nie zasługujecie.',
      en: 'It`s what you said at the ceremony before the attack, when Galactica was being decommissioned. You gave a speech, it sounded like it wasn`t the one you prepared. You said that humanity was a flawed creation, and that people still kill one another for petty jealousy and greed. You said that humanity never asked itself why it deserved to survive. Maybe you don`t.'
    },
    author: { en: 'Sharon - Battlestar Galactica' }
  }, {
    quote: {
      pl: 'Dzieci rodzą się, by zastąpić swoich rodziców. By dzieci mogły w pełni się rozwinąć, rodzice muszą umrzeć.',
    },
    author: { en: 'Leoben - Battlestar Galactica' }
  }, {
    quote: {
      pl: '(...) ludzkość chętniej jednoczy się w dążeniu do wojny niż w dążeniu do pokoju.',
      en: '(...) mankind united with infinitely greater purpose in pursuit of war... than he ever did in pursuit of peace.'
    },
    author: { en: 'Equilibrium' }
  }, {
    quote: {
      pl: 'Jestem nędzarzem, posiadam tylko marzenia, rozsiałem je u twych stóp, stąpaj lekko, gdyż stąpasz po moich marzeniach.',
      en: '(...) being poor, have only my dreams. I have spread my dreams under your feet. Tread softly because you tread on my dreams.'
    },
    author: { en: 'William Butler Yeats (1865–1939)' }
  }, {
    quote: {
      pl: 'Mój Boże! Mógłbym im pomóc! Ale włożyłem już piżamę.',
      en: 'My God! I`ve got to save them! Although I am already in my pajamas.'
    },
    author: { en: 'Prof. Farnsworth - Futurama' }
  }, {
    quote: {
      pl: 'Bycie robotem jest świetne, ale my nie odczuwamy emocji i czasami jest mi przez to bardzo smutno.',
      en: 'I mean, being a robot\'s great, but we don\'t have emotions, and sometimes that makes me very sad.'
    },
    author: { en: 'Bender - Futurama' }
  }, {
    quote: {
      pl: 'Celem wojny nie jest śmierć za ojczyznę, ale sprawienie, aby tamci skurwiele umierali za swoją.',
      en: 'The object of war is not to die for your country but to make the other bastard die for his.'
    },
    author: { en: 'Gen. George Patton (1885-1945)' }
  }, {
    quote: {
      pl: 'Im więcej dowiadujemy się o innych, tym więcej wiemy o sobie samym.',
      en: 'As we learn about each other so we learn about ourselves.'
    },
    author: { en: 'Doctor Who' }
  }, {
    quote: {
      pl: 'Pięć milionów lat, a wciąż chodzi o pieniądze.',
    },
    author: { en: 'Doctor Who' }
  }, {
    quote: {
      pl: 'Chcesz broni? Jesteśmy w bibliotece! Książki! Najlepsza broń na świecie! Ten pokój to największy arsenał jaki moglibyśmy mieć, Uzbrój się!',
      en: 'You want weapons. We\'re in a library. Books are the best weapon in the world. This room\'s the greatest arsenal we could have. Arm yourself!'
    },
    author: { en: 'Doctor Who' }
  }, {
    quote: {
      pl: 'Ja tu jestem geniuszem, a nikt nie stoi obok, żeby być pod wrażeniem!',
      en: 'I\'m being extremely clever up here and there\'s no one to stand around looking impressed!'
    },
    author: { en: 'Doctor Who' }
  }, {
    quote: {
      pl: 'Teraz, albo jesteś liderem, albo idziesz jego śladem, albo złaź z drogi.',
      en: 'Now, you either lead, follow, or get out of the way.'
    },
    author: { en: 'Idiocracy', pl: 'Idiokracja' }
  }, {
    quote: {
      pl: 'Ugryź mój lśniący, metalowy tyłek!',
      en: 'Bite my shiny, metal ass!'
    },
    author: { en: 'Bender - Futurama' }
  }, {
    quote: {
      pl: 'Miałeś kiedyś sen, który wydawał się prawdziwy? A gdybyś nie mógł się z niego obudzić? Jak odróżniłbyś świat snu od realnego?',
      en: 'Have you ever had a dream, Neo, that you were so sure was real? What if you were unable to wake from that dream? How would you know the difference between the dream world and the real world?'
    },
    author: { en: 'Morpheus - Matrix', pl: 'Morfeusz - Matrix' }
  }, {
    quote: {
      pl: 'Witamy na pustyni rzeczywistości.',
      en: 'Welcome to the desert of the real.'
    },
    author: { en: 'Morpheus - Matrix', pl: 'Morfeusz - Matrix' }
  }, {
    quote: {
      pl: 'Weź niebieską pigułkę, a historia się skończy, obudzisz się we własnym łózku i uwierzysz we wszystko, w co zechcesz. Weź czerwoną pigułkę, a zostaniesz w krainie czarów i pokażę ci, dokąd prowadzi królicza nora.',
      en: 'You take the blue pill, the story ends, you wake up in your bed and believe whatever you want to believe. You take the red pill, you stay in Wonderland, and I show you how deep the rabbit hole goes.'
    },
    author: { en: 'Morpheus - Matrix', pl: 'Morfeusz - Matrix' }
  }, {
    quote: {
      pl: 'Niewiedza to rozkosz.',
      en: 'Ignorance is bliss.'
    },
    author: { en: 'Cypher - Matrix' }
  }, {
    quote: {
      pl: 'Ludzie to choroba, rak toczący tę planetę. Wy jesteście plagą... a my jesteśmy lekarstwem.',
      en: 'Human beings are a disease, a cancer of this planet. You’re a plague and we... are the cure.'
    },
    author: { en: 'Agent Smith - Matrix' }
  }, {
    quote: {
      pl: 'Tu nie ma łyzki.',
      en: 'There is no spoon.'
    },
    author: { en: 'Matrix' }
  }, {
    quote: {
      pl: 'Artyści mówią kłamstwa by przekazać prawdę, a politycy kłamią by ją ukryć.',
      en: 'Artists use lies to tell the truth, while politicians use them to cover the truth up.'
    },
    author: { en: 'Evey Hammond - V for Vendetta (2005)', pl: 'Evey Hammond - V jak Vendetta (2005)' }
  }, {
    quote: {
      pl: 'Obywatele nie powinni obawiać się rządu, to rząd powinien obawiać się obywateli.',
      en: 'People shouldn\'t be afraid of their government. Governments should be afraid of their people.'
    },
    author: { en: 'V - V for Vendetta (2005)', pl: 'V - V jak Vendetta (2005)' }
  }, {
    quote: {
      pl: 'Maszyny mogą ustalać prawo, ale nie mogą zachować sprawiedliwości. Tylko istoty ludzkie to potrafią.',
      en: 'Machines can make laws, but they can not preserve justice. Only human beings can do that.'
    },
    author: { en: 'Doctor Who' }
  }, {
    quote: {
      pl: 'Jeśli jest pan doktorem, dlaczego na pana budce jest napisane „policja”?',
    },
    author: { en: 'Amelia Pond - Doctor Who' }
  }, {
    quote: {
      pl: 'Demony zrywają się, kiedy dobry człowiek idzie na wojnę',
      en: 'Demons run when a good man goes to war.'
    },
    author: { en: 'Dorium Maldovar - Doctor Who (by Steven Moffat)' }
  }, {
    quote: { 
      pl: 'Ciało Władcy Czasu to cud. Zwłoki również. Istnieją imperia, które zniszczyłyby ten świat dla jednej komórki.', 
    },
    author: { en: 'Doctor Who' }
  }, {
    quote: {
      pl: 'Lubię kiedy ktoś mówi „jest większa w środku”. Zawsze na to czekam.',
    },
    author: 'Doctor Who'
  }, {
    quote: {
      pl: 'Jeśli nie znasz planów swoich rywali, nie stworzysz dobrych przymierzy.',
    },
    author: { pl: 'Sun Zi (ok. VI w. p.n.e.)' }
  }, {
    quote: {
      pl: 'Kiedy nieprzyjaciel atakuje, cofamy się;\nkiedy nieprzyjaciel zatrzymuje się, nękamy go;\nkiedy nieprzyjaciel chce uniknąć bitwy, atakujemy;\nkiedy nieprzyjaciel uchodzi, następujemy za nim.',
    },
    author: { pl: 'Sztuka Wojny - Sun Zi (ok. VI w. p.n.e.)' }
  }, {
    quote: {
      pl: 'Cywilizacje albo wychodzą w kosmos, albo giną.',
    },
    author: { en: '(?) Carl Sagan (1934–1996)' }
  }, {
    quote: {
      pl: 'Nie chcę wierzyć. Chcę WIEDZIEĆ.',
      en: 'I don`t want to believe. I want to know.'
    },
    author: { en: '(?) Carl Sagan (1934–1996)' }
  }, {
    quote: {
      pl: 'Być może wszyscy żyjemy na śmietniku obcej cywilizacji.',
      en: 'Perhaps we all live in the garbage of an alien civilization.'
    },
    author: { en: 'Adam Wiśniewski-Snerg (1937–1995)' }
  }, {
    quote: {
      pl: 'Mój Boże, ile tu gwiazd.',
      en: 'My God, It`s Full of Stars'
    },
    author: { en: 'Bowman - 2010: Space Odyssey' }
  }, {
    quote: {
      pl: 'Uroda nie jest domeną tylko jednej rasy.',
      en: 'Beauty is not the domain of only one race.'
    },
    author: { en: 'Sayonara (1957)' }
  }, {
    quote: {
      pl: '(jak ogrzać Marsa) Szybkim sposobem jest zrzucenie broni termojądrowej na bieguny.',
      en: '(how to warm Mars) The fast way is to drop thermonuclear weapons over the poles.'
    },
    author: { en: 'Elon Musk' }
  }
]

export default quotes
