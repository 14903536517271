// import { config, C } from './../../utils/utils';

export const C = {
  WEBSOCKET_CONNECT: 'websocket/CONNECT',
  WEBSOCKET_CONNECTING: 'websocket/CONNECTING',
  WEBSOCKET_CONNECTED: 'websocket/CONNECTED',
  WEBSOCKET_DISCONNECTED: 'websocket/DISCONNECTED',
}


export function getServerTime() {
  return {
    type: 'API_GET_SERVER_TIME',
    meta: { remote: true },
  }
}

export function getUniversumTime() {
  return {
    type: 'API_GET_UNIVERSUM_TIME',
    meta: { remote: true },
  }
}

export function remoteActionTest(entry) {
  return {
    type: 'REMOTE_ACTION_TEST',
    meta: { remote: true },
    entry
  };
}

export function socketConnecting() {
  return {
    type: C.WEBSOCKET_CONNECTING
  }
}

export function socketConnected() {
  return {
    type: C.WEBSOCKET_CONNECTED
  }
}

export function socketDisconnected() {
  return {
    type: C.WEBSOCKET_DISCONNECTED
  }
}

export function connect(options = {}) {
  return {
    type: C.WEBSOCKET_CONNECT,
    payload: options.payload || {}
  }
}
