import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import WManagerActions from '../WManagerActions'
// import './WManagerLink.scss'
import withWManager from './withWManager'


class WindowLink extends React.PureComponent {
  static propTypes = {
    // name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }
  static defaultProps = {
    activeStyle: {},
  }

  render() {
    return (
      <span
        className={`nav_window_component ${this.props.className} ${this.props.isActive ? 'active' : ''}`}
        onClick={this.props.onClick}
        style={this.props.isActive ? this.props.activeStyle : {}}
      >
        {this.props.children}
      </span>
    )
  }
}

export default withWManager(WindowLink)
