import React from 'react'
import AppBar from './AppBar'
import AppFooter from './AppFooter'
import './MainLayout.scss'

import WindowsManagerLayout from '../WindowsManager/WindowsManagerLayout'
import WManager from '../WindowsManager/lib/WManager'
import PropTypes from 'prop-types'


class MainLayout extends React.Component {
  manager = null
  static childContextTypes = {
    manager: PropTypes.object,
  }
  getChildContext() {
    return {
      manager: this.manager,
    }
  }
  componentWillMount() {
    this.manager = new WManager()
  }

  render() {
    const { children } = this.props
    console.warn('render Main Layout')

    return (
      <div className={'layout main_layout'}>
        <WindowsManagerLayout manager={this.manager} />
        <header>
          <AppBar />
        </header>
        <main>
          {console.log('render main')}
          {children}
        </main>
        <footer>
          <AppFooter />
        </footer>
      </div>
    )
  }
}

export default MainLayout
