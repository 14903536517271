import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Progress from './Utils/Progress'


class AsyncComponent extends React.PureComponent {
  static propTypes = {
    moduleProvider: PropTypes.func,
    isAuth: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    match: PropTypes.object,
  }
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      Component: null
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    if (this.checkRouteProtection()) return

    if(!this.state.Component) {
      this.props.moduleProvider()
        // .then((response) => new Promise((resolve, reject) => {
        //   setTimeout(function () {
        //     resolve(response)
        //   }, 500)
        // }))
        .then(({ Component }) => this.setState({ Component }))
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillUpdate(nextProps, nextState) {
    this.checkRouteProtection(nextProps)
  }

  isAdminRole() {
    const roles = this.props.user && this.props.user.roles ? this.props.user.roles : []
    // if (roles.indexOf('admin') > -1) return true
    return roles.indexOf('admin') > -1
  }

  checkRouteProtection(props = this.props) {
    if (!this.props.isAuth) return false

    const { router: { history, route } } = this.context
    const { isAuthenticated } = props
    const { location: { pathname } } = route
    if (!isAuthenticated) {
      history.replace(`/login?redirect=${pathname}`)
      return true
    }
    if ((pathname.startsWith('/admin') || pathname.startsWith('/mode')) && !this.isAdminRole()) {
      history.replace('/interface')
      return true
    }
    return false
  }

  render() {
    // console.log('render AsyncComponent')
    const { Component } = this.state
    //The magic happens here!
    // return <Progress />
    return Component
      ? <Component match={this.props.match} />
      : <Progress />
  }
  // : <div>LOADING...</div>
}

export { AsyncComponent }
function mapStateToProps(state) {
  return { 
    isAuthenticated: state.Auth && state.Auth.isAuthenticated, 
    user: state.Auth && state.Auth.user
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AsyncComponent)
