import React from 'react'
import PropTypes from 'prop-types'
import { FontIcon, IconButton } from 'material-ui'
import PersonAddIcon from 'material-ui/svg-icons/social/person-add'
import PersonIcon from 'material-ui/svg-icons/social/person'
import OpenInNewIcon from 'material-ui/svg-icons/action/open-in-new'

import './flaticon_font/_flaticon.scss'
import './Icon.scss'


class Icon extends React.Component {
  static propTypes = {
    iconName: PropTypes.string,
  }
  static defaultProps = {
    iconName: 'default',
    style: {},
    button: false,
    tooltip: null,
  }
  mapNameToClassName() {
    const icons = {
      home: 'fi flaticon-home',
      nation: 'fi flaticon-nation',
      property: 'fi flaticon-property',

      dashboard: 'fi flaticon-dashboard',
      interface: 'fi flaticon-navigate',
      theorys: 'fi flaticon-education', // TODO
      messages: 'fi flaticon-satellite-communication',
      conversations: 'fi flaticon-chat',
      project: 'fi flaticon-student',
      sync_status: 'fi flaticon-construction',
      cog: 'fi flaticon-cog',
      // CELLESTIALS
      star: 'fi flaticon-star',
      planet: 'fi flaticon-planet',
      system: 'fi flaticon-planets-orbits-around-the-sun',
      galaxy: 'fi flaticon-galaxy',
      star_system: 'fi flaticon-star-system',
      // ACTIONS
      sign_up: 'fi flaticon-star',
      sign_in: 'fa fa-sign-in-all',
      learn: 'fi flaticon-learn',
      build: 'fi flaticon-build',
      // RESOURCES
      iron_ore: 'fi flaticon-mine-cart',
      steel: 'fi flaticon-resources-steel',
      energy: 'fi flaticon-resources-energy',
      water: 'fi flaticon-resources-water',
      food: 'fi flaticon-resources-food',
      credits: 'fi flaticon-resources-credit',
      population: 'fi flaticon-people',
      coal: 'fi flaticon-resources-coal',

      '2d': 'fi flaticon-2d',
      '3d': 'fi flaticon-3d',
      default: 'fi flaticon-cross-sign',

      // locales
      'locale-en': 'fi flaticon-locale-en',
      'locale-pl': 'fi flaticon-locale-pl',
    }
    const { iconName } = this.props
    return icons[iconName] || icons.default
  }
  render() {
    const { iconName, style, button, tooltip, ...props } = this.props

    switch (iconName) {
      case 'sign_in': return <PersonIcon />
      case 'sign_up': return <PersonAddIcon />
      case 'open_in_new': return <OpenInNewIcon />
    }

    const iconClassName = ['icon', iconName, this.mapNameToClassName()]
    if (style.margin) style.margin = '0 12px'
    const icon = <FontIcon className={iconClassName.join(' ')} {...props} style={{ ...style, display: 'inline' }} />
    // return (
    //   <FontIcon className={iconClassName.join(' ')} {...props} style={{ ...style, display: 'inline' }} />
    // )
    if (button) return <IconButton tooltip={tooltip}>{icon}</IconButton>
    return icon
  }
}

export default Icon
