import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'

// import { Grid, Row, Col } from 'react-flexbox-grid'
import { IconButton } from 'material-ui'
import './TopBarIconNav.scss'
import * as Icons from '../Icons'
import WindowLink from '../WindowsManager/components/WindowLink'
import LoginButton from 'containers/home/pages/LoginButton'
import RegisterButton from 'containers/home/pages/RegisterButton'


class TopBarIconNav extends React.Component {
  static defaultProps = {
    tooltipPosition: 'bottom-left',
  }
  static propTypes = {
    tooltipPosition: PropTypes.string,
    isAuthenticated: PropTypes.bool,
  }
  static contextTypes = {
    muiTheme: PropTypes.object,
  }

  getItems() {
    return [
      // { isAuthenticated: null, title: 'project', path: '/', exact: true },
      { isAuthenticated: null, title: <FormattedMessage id='main.main_page' defaultMessage='main page' />, icon: 'home', path: '/', exact: true },
      // { isAuthenticated: true, title: 'interface', path: '/interface', exact: true },
      { isAuthenticated: true, title: <FormattedMessage id='interface.nation' defaultMessage='nation' />, icon: 'nation', path: 'nation-window', window: true },
      { isAuthenticated: true, title: <FormattedMessage id='interface.property' defaultMessage='property' />, icon: 'property', path: 'property-window', window: true },
      { isAuthenticated: true, title: <FormattedMessage id='interface.theorys' defaultMessage='theorys' />, icon: 'theorys', path: 'theorys-window', window: true },
      { isAuthenticated: true, title: <FormattedMessage id='interface.conversations' defaultMessage='conversations' />, icon: 'conversations', path: 'conversations-window', window: true },
      { isAuthenticated: true, title: <FormattedMessage id='interface.dashboard' defaultMessage='dashboard' />, icon: 'dashboard', path: 'dashboard-window', window: true },
    ]
  }

  renderItems() {
    const { isAuthenticated } = this.props
    const items = this.getItems()

    // const style = {
    //   padding: 0,
    // }

    return Object.keys(items)
      .map(i => {
        const item = items[i]
        if (item.isAuthenticated && !isAuthenticated) return null
        if (item.isAuthenticated == null && isAuthenticated) return null

        if (item.window) {
          return (
            <WindowLink
              key={`tbin_${item.path}`}
              windowId={item.path}
              type={item.path}
              className={'item'}
              activeStyle={{
                // color: `${this.context.muiTheme.palette.secondaryTextColor} !important`,
                // color: this.context.muiTheme.palette.secondaryTextColor,
                // color: this.context.muiTheme.palette.secondary1Color,
              }}
            >
              <IconButton 
                tooltip={item.title} 
                tooltipPosition={this.props.tooltipPosition}
              >
                <Icons.Icon iconName={item.icon || item.title} style={{  }}/>
              </IconButton>
            </WindowLink>
          )
        }

        return (
          <NavLink
            className={'item'}
            key={item.path}
            to={item.path}
            exact={item.exact}
            activeStyle={{
              // color: `${this.context.muiTheme.palette.secondaryTextColor} !important`,
              // color: this.context.muiTheme.palette.secondaryTextColor,
              // background: this.context.muiTheme.palette.primary1Color,
            }}
          >
            <IconButton tooltip={item.title} tooltipPosition={this.props.tooltipPosition}>
              <Icons.Icon iconName={item.icon || item.title} style={{  }}/>
            </IconButton>
          </NavLink>
        )
      })
  }

  renderRegister() {
    return (
      <NavLink className={'item'} to={'/register'}>
        {/* <RaisedButton primary label="Register" icon={<FontIcon className={'fa fa-user-plus'} />} /> */}
        <RegisterButton tooltipPosition={this.props.tooltipPosition} />
      </NavLink>
    )
  }
  renderLogin() {
    return (
      <NavLink className={'item'} to={'/login'} activeStyle={{ background: 'none' }}>
        {/* <RaisedButton primary label="Register" icon={<FontIcon className={'fa fa-user-plus'} />} /> */}
        <LoginButton tooltipPosition={this.props.tooltipPosition} />
      </NavLink>
    )
  }

  render() {
    return (
      <div className={'icon_menu'}>
        {!this.props.isAuthenticated && this.renderLogin()}
        {!this.props.isAuthenticated && this.renderRegister()}
        {this.renderItems()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { isAuthenticated: state.Auth && state.Auth.isAuthenticated }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch)
}
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBarIconNav)))
